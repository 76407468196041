import { useState } from 'react';
import { signalEditedUser } from '../../../../state/admin-users';
import './style.scss';
import TabHeader from '../../../Shared/TabHeader/TabHeader';
import ListItem from '../../ListItem/ListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import {
  signalSelectedCourseId,
  signalSelectedLessonId,
  signalSelectedQuestionId,
} from '../../../../state/admin-courses';
import { userTemplate } from '../../../../utils/admin/user.utils';
import {
  Form,
  FloatingLabel,
  Tab,
  Tabs,
  Button,
  Row,
  Col,
} from 'react-bootstrap';
import CustomerList from './CustomerList/CustomerList';

const User = ({ editedObject, customers }) => {
  const navigate = useNavigate();

  const [epost, setEpost] = useState(editedObject.email);
  const [losenord, setLosenord] = useState(editedObject.password || '');

  const handleEpostChange = (e) => {
    const epost = e.target.value;
    signalEditedUser.value.email = epost;
    setEpost(epost);
  };

  const handleLosenordChange = (e) => {
    const loseord = e.target.value;
    signalEditedUser.value.password = loseord;
    setLosenord(loseord);
  };

  const handleSelectCourse = (course) => {
    signalSelectedLessonId.value = null;
    signalSelectedQuestionId.value = null;
    signalSelectedCourseId.value = course.courseId;
    navigate(`/admin/courses/`);
  };

  const handleTabSelect = async (selectedKey) => {
    switch (selectedKey) {
      // Information
      case 'info':
        break;
      default:
        // console.log('default');
        break;
    }
  };

  return (
    <Tabs variant="tabs" className="mb-3" onSelect={handleTabSelect}>
      {/* Information */}
      <Tab eventKey="info" title="Information">
        <Row>
          <Col className="col-lg-6 col-sm-12">
            {/* E-post */}
            <FloatingLabel label="E-post" className="mb-3">
              <Form.Control
                type="email"
                placeholder="E-post"
                value={epost}
                onChange={handleEpostChange}
              />
            </FloatingLabel>

            {/* Lösenord */}
            {editedObject.id === userTemplate.id && (
              <FloatingLabel label="Lösenord" className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Lösenord"
                  value={losenord}
                  onChange={handleLosenordChange}
                />
              </FloatingLabel>
            )}
          </Col>
        </Row>
      </Tab>

      {/* Courses */}
      {editedObject.id !== userTemplate.id && (
        <Tab
          eventKey="courses"
          title={
            <TabHeader hasContent={editedObject.courses?.length > 0}>
              Kurser
            </TabHeader>
          }
        >
          <Row>
            <Col className="col-lg-6 col-sm-12">
              <p>
                Gå till en
                <Link to="/admin/customers"> kund</Link> för att tilldela kurser
                till denna användare.
              </p>

              {[...(editedObject.courses || [])]
                .reverse()
                .map((course, index) => (
                  <ListItem
                    name={`${course.name} (v${course.courseVersion})`}
                    key={index}
                    isSelectable={false}
                  >
                    <Button
                      variant="outline-secondary"
                      onClick={() => handleSelectCourse(course)}
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                  </ListItem>
                ))}
            </Col>
          </Row>
        </Tab>
      )}

      {/* Customers */}
      {editedObject.id !== userTemplate.id && (
        <Tab
          eventKey="customers"
          title={
            <TabHeader hasContent={editedObject.customers?.length > 0}>
              Kunder
            </TabHeader>
          }
        >
          <CustomerList editedObject={editedObject} customers={customers} />
        </Tab>
      )}
    </Tabs>
  );
};

export default User;
