import React from 'react';
import { InputGroup } from 'react-bootstrap';
import './style.scss';

function ListItem({
  name,
  title = '',
  active,
  isSelectable = true,
  onClick,
  onDoubleClick,
  children,
  ...props
}) {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') onClick();
  };

  return (
    <InputGroup
      className={`component-list-item ${active ? 'active' : ''} ${
        isSelectable ? 'selectable' : ''
      }`}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onKeyDown={handleKeyDown}
      {...props}
    >
      <InputGroup.Text title={title}>{name}</InputGroup.Text>
      {children}
    </InputGroup>
  );
}

export default ListItem;
