import React, { useState } from 'react';
import { ButtonGroup, Form, ToggleButton } from 'react-bootstrap';
import { FRAGETYP } from '../../../../../utils/constants';
import Multi from './Multi';
import Radio from './Radio';
import { signalEditedQuestion } from '../../../../../state/admin-courses';
import './style.scss';

const CorrectAnswer = () => {
  const editedObject = signalEditedQuestion.value;

  const [, setRenderComponent] = useState(0); // Trigger re-render, Q&D

  const handleYesNoAnswerChange = (e) => {
    let value = e.target.value;
    let answer = [];

    if (value.length === 0 || value === '-1') {
      answer = [];
    } else {
      answer = [value];
    }

    editedObject.correctAnswer = answer;
    editedObject.isDirty = true;
    setRenderComponent((prev) => prev + 1);
  };

  const handleNumberAnswerChange = (e) => {
    let value = e.target.value;
    let answer = [];

    if (value.length === 0) {
      answer = [];
      return;
    } else {
      answer = [value];
    }

    editedObject.correctAnswer = answer;
    editedObject.isDirty = true;
    setRenderComponent((prev) => prev + 1);
  };

  return (
    <>
      {/* YES/NO */}
      {editedObject.type === FRAGETYP.YESNO && (
        <div className="d-flex align-items-center">
          <div className="me-3">Rätt svar:</div>

          <ButtonGroup>
            <ToggleButton
              id="radio-none"
              type="radio"
              variant="outline-light"
              name="radio"
              value="-1"
              checked={
                editedObject.correctAnswer.length === 0 ||
                editedObject.correctAnswer.includes('-1')
              }
              onChange={handleYesNoAnswerChange}
            >
              Inget
            </ToggleButton>
            <ToggleButton
              id="radio-yes"
              type="radio"
              variant="outline-success"
              name="radio"
              value="1"
              checked={editedObject.correctAnswer.includes('1')}
              onChange={handleYesNoAnswerChange}
            >
              Ja
            </ToggleButton>
            <ToggleButton
              id="radio-no"
              type="radio"
              variant="outline-danger"
              name="radio"
              value="0"
              checked={editedObject.correctAnswer.includes('0')}
              onChange={handleYesNoAnswerChange}
            >
              Nej
            </ToggleButton>
          </ButtonGroup>
        </div>
      )}

      {/* NUMBER */}
      {editedObject.type === FRAGETYP.NUMBER && (
        <div className="d-flex align-items-center">
          <div className="me-3">Rätt&nbsp;svar:</div>

          <Form.Control
            type="text"
            className="input-number"
            placeholder="0"
            value={editedObject.correctAnswer[0] || ''}
            onKeyDown={(e) => {
              if (!/^[0-9]*$/.test(e.key) && e.key !== 'Backspace') {
                e.preventDefault();
              }
            }}
            onChange={handleNumberAnswerChange}
          />
        </div>
      )}

      {/* TEXT */}
      {editedObject.type === FRAGETYP.TEXT && (
        <>
          {/* <Form.Control
            as="textarea"
            rows={3}
            placeholder="text..."
            className="input-text d-flex"
            onChange={handleTextAnswerChange}
          /> */}
        </>
      )}

      {/* MULTI */}
      {editedObject.type === FRAGETYP.MULTI && <Multi />}

      {/* RADIO */}
      {editedObject.type === FRAGETYP.RADIO && <Radio />}
    </>
  );
};

export default CorrectAnswer;
