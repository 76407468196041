const { signal } = require('@preact/signals-react');

// Dark mode
export const signalDarkMode = signal(false);

// Loading-bar
export const signalLoadingBar = signal(0);
export const signalLoadingBarRef = signal(null);

// Confirm Modal
export const signalConfirmShow = signal(false);
export const signalConfirmCallback = signal(null);
export const signalConfirmOptions = signal(null);

// User
export const signalUserId = signal(null);

// Lesson
export const signalLessonData = signal(false);
export const signalSlideId = signal(false);
