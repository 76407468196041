export const ACTION = Object.freeze({
  NONE: 0,
  SAVE: 1,
  DELETE: 2,
  UPDATE: 3,
  RELOAD: 4,
});

export const ATTACHMENT_TYPE = Object.freeze({
  IMAGE: 0,
  AUDIO: 1,
  VIDEO: 2,
  DOCUMENT: 3,
  EXPORT: 4,
});

export const FRAGETYP = Object.freeze({
  YESNO: 0,
  NUMBER: 1,
  TEXT: 2,
  MULTI: 3,
  INFORMATION: 4,
  RADIO: 5,
  END: 1000,
});

export const USER_TYPE = Object.freeze({
  SERVICEADMINISTRATOR: 0,
  CUSTOMERADMINISTRATOR: 1,
  USER: 2,
  RETAILERADMINISTRATOR: 3,
  SYSTEMACCOUNT: 99,
});

export const CUSTOMER_EXTERNALRESOURCE_TYPE = Object.freeze({
  FONT: 0,
  LOGO: 1,
  CSS: 2,
});
