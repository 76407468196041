import React, { useEffect, useState } from 'react';
import { registerMap } from 'echarts';
import ReactEcharts from 'echarts-for-react';
import worldMap from './world.json';

// https://github.com/apache/echarts-www/blob/master/asset/map/json/world.json

registerMap('world', worldMap);

function Chart() {
  const initialData = [
    { name: 'Sweden', value: 100 },
    { name: 'China', value: 500 },
    { name: 'United States', value: 300 },
    { name: 'India', value: 200 },
    { name: 'Russia', value: 100 },
  ];

  const [data, setData] = useState(initialData);

  useEffect(() => {
    const interval = setInterval(() => {
      setData((prevData) => {
        return prevData
          .map((item) => ({
            ...item,
            value: item.value + Math.floor(Math.random() * 50),
          }))
          .sort((a, b) => b.value - a.value);
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const getOption = () => {
    return {
      theme: 'dark',
      title: {
        text: 'World Map with Racing Data',
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c}',
      },
      visualMap: {
        min: 0,
        max: 1000,
        left: 'left',
        top: 'bottom',
        text: ['High', 'Low'],
        calculable: true,
      },
      series: [
        {
          name: 'Countries',
          type: 'map',
          mapType: 'world',
          roam: true,
          label: {
            show: false,
          },
          data: data,
        },
      ],
    };
  };

  return (
    <ReactEcharts
      option={getOption()}
      style={{ height: '100vh', width: '100%' }}
      theme={'dark'}
    />
  );
}

export default Chart;
