export const STYLE_EDITOR_CONFIG = {
  tabSize: 2,
  lineNumbers: 'off',
  scrollBeyondLastLine: false,
  minimap: {
    enabled: true,
  },
  automaticLayout: true,
};

export const EDITOR_CSS_DEFAULT_CONTENT = `/* Add your custom CSS here */`;
