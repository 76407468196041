import { useState, useEffect } from 'react';
import { apiClient } from '../api/api.client';

export const useApiGet = (url) => {
  const [data, setData] = useState(null);
  const [isDone, setIsDone] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fnc = () => {
      //console.log('url', url);

      apiClient
        .get(url)
        .then((response) => {
          let data = response.data;
          //console.log('data', data);
          setData(data);
          setIsDone(true);
        })
        .catch((error) => {
          setError(error);
        });
    };
    fnc();
  }, [url]);

  return { error, isDone, data };
};
