import { Container } from 'react-bootstrap';
import { useDarkMode } from '../../hooks/useDarkMode';
import logo from '../../assets/images/logo.png';
import './style.scss';

function Welcome() {
  useDarkMode();

  return (
    <Container>
      <div className="component-welcome">
        <div className="text-center">
          <img src={logo} alt="logo" width="100" className="logo" />
          <h1>Digital Learning</h1>
          <p className="text-muted">— Enkelt lärande —</p>
        </div>
      </div>
    </Container>
  );
}

export default Welcome;
