import { ATTACHMENT_TYPE, FRAGETYP } from '../constants';
import { msToTimespan, parallel } from '../utils';
import { increaseLoadingBarProgress } from '../../state/app-functions';
import { changeFavicon } from '../image.util';
import { placeholderSlideImage, previewFavicon } from '../base64.images';
import { questionTemplate } from '../admin/course.utils';
import { apiClient, handleApiError } from '../../api/api.client';
import { signalLessonData } from '../../state/app.signals';

export async function getLessonData(userId, courseId, lessonId, lessonPreview) {
  if (lessonPreview) {
    changeFavicon(previewFavicon);

    // Get lesson (preview) from LocalStorage
    if (lessonPreview.questions) {
      lessonPreview.instancedQuestions = lessonPreview.questions;
    }
    lessonPreview.isPreview = true;

    const questionsWithAttachments = lessonPreview.instancedQuestions.filter(
      (q) => q.attachmentId
    );

    const totalCount = questionsWithAttachments.length;

    // TODO: Move to separate file
    function getAttachmentContent(question) {
      return apiClient
        .get(`question/${question.id}/attachment`)
        .then((res) => {
          question.attachmentContent = res.data.attachmentContent;

          increaseLoadingBarProgress(totalCount);
        })
        .catch((error) => {
          question.attachmentContent = placeholderSlideImage;
          question.attachmentType = ATTACHMENT_TYPE.IMAGE;
          handleApiError(error, 'Det gick inte att hämta frågans attachment');
        });
    }

    await parallel(questionsWithAttachments, getAttachmentContent);

    return lessonPreview;
  }

  // console.log(`lesson/${userId}/${courseId}/${lessonId}`);

  // Get lesson from api
  try {
    const url = `lesson/${userId}/${courseId}/${lessonId}`;
    const result = await apiClient.get(url);
    const lesson = addEndPage(result.data);
    return lesson;
  } catch (error) {
    handleApiError(error, 'Det gick inte att hämta lektionen');
  }

  return null;
}

export async function startLesson(userId, lessonId, lesson) {
  console.log('lesson', lesson);
  if (!lesson || lesson.isPreview === true) return;
  // TODO: Check !isComplete before setting time?

  const url = `lesson/${userId}/${lessonId}/timeframe`;
  const body = {
    startTimeChanged: true,
  };

  apiClient.patch(url, body);
}

export async function completeLesson(userId, lessonId, lesson) {
  if (!lesson || lesson.isPreview === true) return;
  // TODO: Check !isComplete before setting time?

  const url = `lesson/${userId}/${lessonId}/timeframe`;
  const body = {
    stopTimeChanged: true,
  };

  console.log('completeLesson', body);

  apiClient.patch(url, body);

  // TODO: Set isComplete=true?
}

export async function saveAnswer(userId, data) {
  console.log('saveAnswer');
  const questionId = data.id;
  const answers = data.answers;

  //if (data.hasAnswered) return;

  const url = `answer/${userId}/${questionId}`;
  const body = {
    answers: answers,
    answersChanged: true,
  };

  return await apiClient.patch(url, body);
}

export async function saveLessonProgress(
  userId,
  lesson,
  index,
  timeSpentArray
) {
  if (lesson.isPreview === true) return;

  const question = lesson.instancedQuestions[index];

  // Set lesson as completed if we are on the last question/slide
  if (question.type === FRAGETYP.END) {
    console.log('completeLesson', lesson);
    completeLesson(userId, lesson.id, lesson);
    return;
  }

  // Time spent on question
  const url = `answer/${userId}/${question.id}`;
  const body = {
    timeSpentOnQuestionChanged: true,
    timeSpentOnQuestion: msToTimespan(timeSpentArray[index]),
  };

  // Mark information slides as answered
  if (question.type === FRAGETYP.INFORMATION) {
    body.hasAnswered = true;
    body.hasAnsweredChanged = true;
  }

  await apiClient.patch(url, body);
}

export async function deleteAnswer(userId, questionId) {
  console.log('deleteAnswer');

  const url = `answer/${userId}/${questionId}`;
  return await apiClient.delete(url);
}

function addEndPage(lesson) {
  const end = { ...questionTemplate, id: 'end', type: FRAGETYP.END };
  lesson.instancedQuestions.push(end);
  console.log('addEndPage', lesson);
  return lesson;
}

export function showNextSlide(slide, hasAnswered) {
  // hasAnswered is true when the user clicks "Svara"

  // console.log('showNextSlide', slide, 'hasAnswered:', hasAnswered);
  if (!slide) return;
  if (!slide.hasAnswered) slide.hasAnswered = false;
  if (typeof hasAnswered !== 'undefined') slide.hasAnswered = hasAnswered;

  const showNext =
    slide.type === FRAGETYP.INFORMATION || slide.hasAnswered === true;

  // console.log('showNext', showNext);

  const nextElement = document.getElementsByClassName(
    'carousel-control-next'
  )[0];

  if (!nextElement) return;

  nextElement.style.display = showNext ? 'flex' : 'none';
}

export const goToOverview = (navigate, locationState) => {
  signalLessonData.value = null;

  navigate(
    `/instancedcourse?id=${locationState.courseId}&customerid=${locationState.customerId}&userid=${locationState.userId}`
  );
};
