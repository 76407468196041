import { useEffect, useRef, useState } from 'react';

const Timer = ({ activeIndex, onTimeUpdate, isActive }) => {
  const [startTime, setStartTime] = useState(Date.now());
  const timerRef = useRef(null);

  useEffect(() => {
    if (isActive) {
      setStartTime(Date.now());

      timerRef.current = setInterval(() => {
        const now = Date.now();
        onTimeUpdate(now - startTime);
        setStartTime(now);
      }, 1000);
    } else {
      clearInterval(timerRef.current);
    }

    return () => clearInterval(timerRef.current);
  }, [isActive, onTimeUpdate, startTime]);

  useEffect(() => {
    if (isActive) {
      setStartTime(Date.now());
    }
  }, [activeIndex, isActive]);

  return null;
};

export default Timer;
