import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { userTemplate } from '../../../utils/admin/user.utils';
import AddButton from '../../Shared/AddButton/AddButton';
import './style.scss';
import { signalSelectedUserId } from '../../../state/admin-users';
import ListItem from '../ListItem/ListItem';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../PageHeader';
import { useQuery } from 'react-query';
import { queryUsers } from '../../../api/queries/queries.user';

const Users = () => {
  const { data, isLoading } = useQuery(queryUsers());
  const navigate = useNavigate();

  const handleEditUser = (e, userId) => {
    e.stopPropagation();

    signalSelectedUserId.value = userId;
    navigate(`/admin/users/${userId}`);
  };

  const handleNewUser = () => {
    signalSelectedUserId.value = null;
    navigate(`/admin/users/${userTemplate.id}`);
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <PageHeader title="Användare" icon="users" />

      <Row>
        <Col className="col-lg-6 col-sm-12">
          {/* Users */}
          {data.map(function (user, index) {
            return (
              <ListItem
                name={user.name}
                key={index}
                active={signalSelectedUserId.value === user.id}
                onClick={async (e) => {
                  console.log('Selected User', user.id);
                  signalSelectedUserId.value = user.id;
                  handleEditUser(e, user.id);
                }}
              />
            );
          })}

          <div className="mt-2">
            {/* Add User */}
            <AddButton onClick={handleNewUser} />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Users;
