import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toastMessage } from '../../../state/app-functions';
import RippleButton from '../../Shared/RippleButton/RippleButton';
import ButtonAnswerText from './ButtonAnswerText';
import Answer from '../Answer/Answer';
import { encode } from 'js-base64';
import { signalLessonData, signalUserId } from '../../../state/app.signals';
import {
  deleteAnswer,
  saveAnswer,
  showNextSlide,
} from '../../../utils/home/lesson';
import './style.scss';
import { handleApiError } from '../../../api/api.client';

const Multi = ({ slide }) => {
  const [hasAnswered, setHasAnswered] = useState(slide.hasAnswered);

  const getAnswers = () => {
    if (slide.answers === null) slide.answers = [];

    const answerObj = slide.answers.reduce(
      (ac, a) => ({ ...ac, [a]: true }),
      {}
    );
    //console.log('getAnswers', slide.answers, answerObj);
    return answerObj;
  };

  const { register, handleSubmit } = useForm({
    defaultValues: getAnswers(),
  });

  const getFormAnswers = (obj) => {
    for (const x in obj) if (obj[x] === false) delete obj[x];
    return Object.keys(obj);
  };

  const handleOnChange = (e) => {
    setHasAnswered(false);
  };

  const onSubmit = async (data) => {
    const answers = getFormAnswers(data);
    slide.answers = answers;
    //console.log('answers', answers);

    if (signalLessonData.value.isPreview) {
      toastMessage(
        'Ditt svar är inte sparat eftersom detta är en förhandsgranskning'
      );
      setHasAnswered(true);
      showNextSlide(slide, true);
      return;
    }

    try {
      await deleteAnswer(signalUserId.value, slide.id);

      setHasAnswered(true);
      showNextSlide(slide, true);

      await saveAnswer(signalUserId.value, slide);
    } catch (error) {
      handleApiError(error, 'Ett fel uppstod när ditt svar skulle sparas');
    }
  };

  return (
    <div className="component-fraga-multi">
      <Form>
        {slide.possibleAnswers?.map((value, index) => (
          <div key={`checkbox-${index}`} className="mb-3">
            <Form.Check
              type="checkbox"
              id={`checkbox-${slide.id}-${index}`}
              variant="success"
              label={value}
              value={encode(value)}
              {...register(encode(value), {
                onChange: handleOnChange,
              })}
            />
          </div>
        ))}
      </Form>
      <RippleButton
        // disabled={slide.hasAnswered}
        className="btn btn-lg btn-secondary"
        onClick={handleSubmit(onSubmit)}
      >
        <ButtonAnswerText hasAnswered={hasAnswered}>Svara</ButtonAnswerText>
      </RippleButton>
      <Answer slide={slide} hasAnswered={hasAnswered} />
    </div>
  );
};

export default Multi;
