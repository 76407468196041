import { customerTemplate } from '../../utils/admin/customer.utils';
import { sortByProp } from '../../utils/utils';
import { apiClient } from '../api.client';

export const queryCustomers = () => {
  return {
    queryKey: ['customers'],
    queryFn: async () => {
      const result = await apiClient.get(`customers`);
      return result.data.sort(sortByProp('name'));
    },
  };
};

export const queryCustomerDetail = (id) => ({
  queryKey: ['customers', 'detail', id],
  queryFn: async () => {
    if (id === customerTemplate.id) return { ...customerTemplate };

    const result = await apiClient.get(`customer/${id}`);
    return result.data;
  },
});

export const queryCustomerDetailBinaries = (id) => ({
  queryKey: ['customers', 'detail', id, 'binaries'],
  queryFn: async () => {
    const result = await apiClient.get(`customer/${id}/?binaries=true`);
    return result.data;
  },
});

export const queryCustomerDetailCourseDetail = (id) => ({
  queryKey: ['customers', 'detail', id, 'coursedetails'],
  queryFn: async () => {
    const result = await apiClient.get(`customer/${id}/?courseDetails=true`);
    return result.data;
  },
});

export const queryExternalResources = (ids) => {
  return ids.map((id) => ({
    queryKey: ['externalresources', id],
    queryFn: async () => {
      const result = !id
        ? { data: {} }
        : await apiClient.get(`externalresources/${id}`);
      return result.data;
    },
  }));
};

export const queryInstansiatedCourse = (id) => ({
  queryKey: ['instancedcourses', id],
  queryFn: async () => {
    const result = await apiClient.get(`instancedcourses/${id}`);
    return result.data;
  },
});

export const queryInstansiatedCourses = (ids) => {
  return ids.map((id) => ({
    queryKey: ['instancedcourses', id],
    queryFn: async () => {
      const result = !id
        ? { data: {} }
        : await apiClient.get(`instancedcourses/${id}`);
      return result.data;
    },
  }));
};

export const queryInstansiatedCourseStatistics = (id) => ({
  queryKey: ['instancedcourses', id, 'statistics'],
  queryFn: async () => {
    const result = await apiClient.get(`instancedcourses/${id}/statistics`);
    return result.data;
  },
});
