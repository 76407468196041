import React from 'react';
import './style.scss';

const Glow = ({ type, className, borderRadius, children }) => {
  let typeClass = '';

  switch (type) {
    case 'legendary':
      typeClass = 'LegendaryAchievement';
      break;
    case 'epic':
      typeClass = 'EpicAchievement';
      break;
    case 'rare':
      typeClass = 'RareAchievement';
      break;
    case 'common':
      typeClass = 'CommonAchievement';
      break;
    default:
      typeClass = 'LegendaryAchievement';
      break;
  }

  return (
    <div className={`component-glow ${className}`}>
      <div className={`AchievementIconWrapper ${typeClass} `}>
        <div className="AchievementIconGlowContainerRoot">
          <div className="AchievementIconGlowContainer">
            <div className="AchievementIconGlow"></div>
          </div>
        </div>
        <div className="fakeIcon" style={{ borderRadius: borderRadius }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Glow;
