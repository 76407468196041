export const transformEditorHtml = (html) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;

  transformIframe(tempDiv);
  transformTable(tempDiv);

  return tempDiv.innerHTML;
};

const transformIframe = (element) => {
  const iframes = element.getElementsByTagName('iframe');

  for (let iframe of iframes) {
    let src = iframe.src;

    if (src.includes('youtube.com') || src.includes('youtu.be')) {
      // Add the 'youtube-player' class to the iframe so that we can target it later
      if (!iframe.classList.contains('youtube-player')) {
        iframe.classList.add('youtube-player');
        markElementAsTransformed(iframe);
      }

      // Add enablejsapi=1 so that we can control the video with the YouTube API
      const enableJsApi = 'enablejsapi=1';
      if (!src.includes(enableJsApi)) {
        src.includes('?') ? (src += '&') : (src += '?');
        iframe.src = `${src}${enableJsApi}`;

        markElementAsTransformed(iframe);
      }
    }
  }
};

const transformTable = (element) => {
  const tables = element.getElementsByTagName('table');

  for (let table of tables) {
    // Apply a 1px border to <td> elements that have a border-color style
    const tdsBorderColor = table.querySelectorAll('td[style*="border-color"]');
    for (let td of tdsBorderColor) {
      td.style.borderWidth = '1px';
      markElementAsTransformed(table);
    }
  }
};

const markElementAsTransformed = (element) => {
  element.setAttribute('data-transformed', 'true');
};
