import { sortByProp } from '../../utils/utils';
import { apiClient } from '../api.client';

export const queryCourses = () => {
  return {
    queryKey: ['courses'],
    queryFn: async () => {
      const result = await apiClient.get(`courses`);
      return result.data.sort(sortByProp('name'));
    },
  };
};
