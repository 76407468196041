import { useQueryClient } from 'react-query';

const useExactQueryClient = () => {
  const queryClient = useQueryClient();

  const invalidateExactQueries = (key) => {
    queryClient.invalidateQueries(key, { exact: true });
  };

  const removeExactQueries = (key) => {
    queryClient.removeQueries(key, { exact: true });
  };

  const fetchExactQuery = (key, fetchFn) => {
    return queryClient.fetchQuery(key, fetchFn);
  };

  const refetchExactQueries = (key) => {
    return queryClient.refetchQueries(key, { exact: true });
  };

  return {
    invalidateExactQueries,
    removeExactQueries,
    fetchExactQuery,
    refetchExactQueries,
  };
};

export default useExactQueryClient;
