import { apiClient, handleApiError } from '../../api/api.client';
import { CUSTOMER_EXTERNALRESOURCE_TYPE } from '../constants';
import { loadFont } from '../font.utils';
import { changeFavicon } from '../image.util';

const findObjectByExternalResourceId = (obj, id) => {
  for (const key in obj) {
    if (obj[key].externalResourceId === id) {
      return { key, value: obj[key] };
    }
  }
  return null;
};

export const getInstancedCourse = async (instancedCourseId) => {
  return apiClient
    .get(`instancedcourses/${instancedCourseId}`)
    .then((result) => {
      console.log(`Course ${result.data.name} loaded`);
      return result.data;
    })
    .catch((error) => {
      handleApiError(error, 'Det gick inte att hämta den instansierade kursen');
    });
};

export const loadExternalResources = async (customer) => {
  sessionStorage.removeItem('css');
  sessionStorage.removeItem('favicon');
  sessionStorage.removeItem('logoLight');
  sessionStorage.removeItem('logoDark');

  if (!customer.externalResources) {
    console.log('No external resources found');
    return;
  }

  const metadata = JSON.parse(customer.metaData);

  for (const element of customer.externalResources) {
    // console.log('External resource:', element);

    switch (element.type) {
      case CUSTOMER_EXTERNALRESOURCE_TYPE.FONT:
        // Font
        console.log('- Font found', element);
        const fontFamily = await loadFont(element.name, element.content);
        // document.body.style.fontFamily = fontFamily;
        console.log(`  Font ${fontFamily} applied to <body>`);
        break;
      case CUSTOMER_EXTERNALRESOURCE_TYPE.LOGO:
        // Logos and favicon
        console.log('- Logo found', element);
        const objLogo = findObjectByExternalResourceId(metadata, element.id);
        sessionStorage.setItem(objLogo.key, element.content);
        console.log(`  ${objLogo.key} saved to session storage`);
        break;
      case CUSTOMER_EXTERNALRESOURCE_TYPE.CSS:
        // Style
        console.log('- Style found', element);
        sessionStorage.setItem('css', element.content);
        console.log(`  Style saved to session storage`);
        break;
      default:
        console.error(
          `Unknown External Resouce type: ${element.type}`,
          element
        );
    }
  }

  const faviconSession = sessionStorage.getItem('favicon');
  changeFavicon(faviconSession);

  console.log(`Done loading external resources`);
};
