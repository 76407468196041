import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Timer from '../Shared/Timer/Timer';
import { usePageVisibility } from '../../hooks/usePageVisibility';

const slides = [
  {
    src: 'slide1.jpg',
    alt: 'First slide',
    label: 'First slide label',
    text: 'Nulla vitae elit libero, a pharetra augue mollis interdum.',
  },
  {
    src: 'slide2.jpg',
    alt: 'Second slide',
    label: 'Second slide label',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  {
    src: 'slide3.jpg',
    alt: 'Third slide',
    label: 'Third slide label',
    text: 'Praesent commodo cursus magna, vel scelerisque nisl consectetur.',
  },
];

const Test1 = () => {
  const isPageVisible = usePageVisibility();

  const [startTime, setStartTime] = useState(Date.now());
  const [activeIndex, setActiveIndex] = useState(0);
  const [timeSpentArray, setTimeSpentArray] = useState(
    new Array(slides.length).fill(0)
  );

  const handleSelect = (selectedIndex) => {
    const now = Date.now();
    const updatedTimeSpentArray = [...timeSpentArray];

    // Update the time spent on the current slide
    updatedTimeSpentArray[activeIndex] += now - startTime;

    // Update state
    setTimeSpentArray(updatedTimeSpentArray);
    setActiveIndex(selectedIndex);
    setStartTime(now);
  };

  const handleTimeUpdate = (timeSpent) => {
    const updatedTimeSpentArray = [...timeSpentArray];
    updatedTimeSpentArray[activeIndex] += timeSpent;
    setTimeSpentArray(updatedTimeSpentArray);
  };

  return (
    <div>
      <Carousel activeIndex={activeIndex} onSelect={handleSelect}>
        {slides.map((slide, index) => (
          <Carousel.Item key={index}>
            <img className="d-block w-100" src={slide.src} alt={slide.alt} />
            <Carousel.Caption>
              <h3>{slide.label}</h3>
              <p>{slide.text}</p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>

      <Timer
        activeIndex={activeIndex}
        onTimeUpdate={handleTimeUpdate}
        isActive={isPageVisible}
      />

      <div>
        {slides.map((slide, index) => (
          <p key={index}>
            Time spent on slide {index + 1}: {timeSpentArray[index] / 1000}{' '}
            seconds
          </p>
        ))}
      </div>
    </div>
  );
};

export default Test1;
