import React, { useEffect, useRef, useState } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { setPossibleAndCorrectAnswers } from '../../../Courses/utils';
import { signalEditedQuestion } from '../../../../../state/admin-courses';

const Radio = () => {
  const editedObject = signalEditedQuestion.value;

  const [answers, setAnswers] = useState([]);
  const [newAnswer, setNewAnswer] = useState('');
  const inputRef = useRef(null);

  // Create answers objects from possibleAnswers and correctAnswer
  useEffect(() => {
    setAnswers(
      editedObject.possibleAnswers.map((answer, index) => ({
        id: index,
        text: answer,
        correct: editedObject.correctAnswer.includes(answer),
      }))
    );
  }, [editedObject.possibleAnswers, editedObject.correctAnswer]);

  useEffect(() => {
    inputRef.current.focus(); // Set focus to the input field after answer change
  }, [answers]);

  const handleInputChange = (event) => {
    setNewAnswer(event.target.value);
  };

  const addPossibleAnswer = () => {
    if (newAnswer.trim().length === 0) return;
    if (answers.some((answer) => answer.text === newAnswer)) {
      // Answer already exists
      setNewAnswer('');
      inputRef.current.focus();
      return;
    }

    const updatedAnswers = [
      ...answers,
      { id: answers.length + 1, text: newAnswer, correct: false },
    ];

    setPossibleAndCorrectAnswers(updatedAnswers);
    setAnswers(updatedAnswers);
    setNewAnswer('');
  };

  const toggleAnswerCorrect = (answerId) => {
    const updatedAnswers = answers.map((answer) =>
      answer.id === answerId
        ? { ...answer, correct: true }
        : { ...answer, correct: false }
    );

    // console.log('updatedAnswers', updatedAnswers);

    setPossibleAndCorrectAnswers(updatedAnswers);
    setAnswers(updatedAnswers);
  };

  const removePossibleAnswer = (answerId) => {
    const updatedAnswers = answers.filter((answer) => answer.id !== answerId);

    setPossibleAndCorrectAnswers(updatedAnswers);
    setAnswers(updatedAnswers);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') addPossibleAnswer();
  };

  return (
    <>
      {/* RADIO */}
      <InputGroup className="mb-3">
        <Form.Control
          placeholder="Svarsalternativ"
          value={newAnswer}
          ref={inputRef}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyPress}
        />
        <Button variant="outline-secondary" onClick={addPossibleAnswer}>
          Lägg till
        </Button>
      </InputGroup>

      {/* Possible answers */}
      <Form>
        {answers.map((answer) => (
          <div key={answer.id} className="d-flex align-items-center">
            <Form.Check
              type="radio"
              id={`answer-${answer.id}`}
              name="radio"
              label={`${answer.text}`}
              checked={answer.correct}
              onChange={() => toggleAnswerCorrect(answer.id)}
            />

            <FontAwesomeIcon
              icon={faTimes}
              className="ms-3 text-danger delete-answer"
              size="lg"
              onClick={() => removePossibleAnswer(answer.id)}
            />
          </div>
        ))}
      </Form>
    </>
  );
};

export default Radio;
