import React from 'react';
import './style.scss';
import { useDarkMode } from '../../hooks/useDarkMode';

const PageNotFound = () => {
  useDarkMode();

  return (
    <div className="page-notfound">
      <div className="page-content">
        <div className="cat">
          <div className="ear ear--left"></div>
          <div className="ear ear--right"></div>
          <div className="face">
            <div className="eye eye--left">
              <div className="eye-pupil"></div>
            </div>
            <div className="eye eye--right">
              <div className="eye-pupil"></div>
            </div>
            <div className="muzzle"></div>
          </div>
        </div>

        <div className="title">404</div>
      </div>

      <div className="background-wrapper">
        <div className="dots-1 dots"></div>
        <div className="dots-2 dots"></div>
        <div className="dots-3 dots"></div>
      </div>
    </div>
  );
};

export default PageNotFound;
