import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import CourseModal from '../CourseModal/CourseModal';
import AddButton from '../../Shared/AddButton/AddButton';
import LessonModal from '../LessonModal/LessonModal';
import {
  courseTemplate,
  lessonTemplate,
  questionTemplate,
} from '../../../utils/admin/course.utils';
import {
  getCourseLessons,
  getLessonQuestions,
  questionSelected,
  previewLesson,
} from './utils';
import {
  signalCourses,
  signalEditedCourse,
  signalEditedLesson,
  signalEditedQuestion,
  signalLessons,
  signalQuestions,
  signalSelectedCourseId,
  signalSelectedLessonId,
  signalSelectedQuestionId,
} from '../../../state/admin-courses';
import './style.scss';
import ListItem from '../ListItem/ListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPen, faSort } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import QuestionSortModal from '../QuestionSortModal/QuestionSortModal';
import PageHeader from '../PageHeader';
import { useQueries } from 'react-query';
import { queryCourses } from '../../../api/queries/queries.course';
import { queryLessons } from '../../../api/queries/queries.lesson';
import { queryQuestions } from '../../../api/queries/queries.question';
import QuestionModal from '../QuestionModal/QuestionModal';

const Courses = () => {
  const navigate = useNavigate();

  const results = useQueries([
    queryCourses(),
    queryLessons(),
    queryQuestions(),
  ]);

  const isSuccess = results.every((result) => result.isSuccess);

  const [coursesQuery, lessonsQuery, questionsQuery] = results;

  const [sortQuestions, setSortQuestions] = useState(false);

  const handleNewCourse = () => {
    signalEditedCourse.value = { ...courseTemplate, name: 'Ny kurs' };
  };

  const handleNewLesson = () => {
    signalEditedLesson.value = { ...lessonTemplate, name: 'Ny lektion' };
  };

  const handleNewQuestion = () => {
    signalEditedQuestion.value = { ...questionTemplate, name: 'Ny fråga' };
  };

  const handleSortQuestions = () => {
    setSortQuestions(true);
  };

  const handleSelectedQuestion = async (question) => {
    await questionSelected(question);
  };

  const handleCourseModalClose = () => {
    signalEditedCourse.value = null;
  };

  const handleLessonModalClose = () => {
    signalEditedLesson.value = null;
  };

  const handleQuestionModalClose = () => {
    signalEditedQuestion.value = null;
  };

  const handleSortQuestionsModalClose = () => {
    setSortQuestions(false);
  };

  const handleEditCourse = (e, course) => {
    e.stopPropagation();
    signalEditedCourse.value = { ...course };
  };

  const handleEditLesson = (e, lesson) => {
    e.stopPropagation();
    signalEditedLesson.value = { ...lesson };
  };

  const handlePreviewLesson = async (e, lesson) => {
    e.stopPropagation();
    await previewLesson(navigate, lesson.id);
  };

  if (!isSuccess) {
    return <></>;
  }

  signalCourses.value = coursesQuery.data;
  signalLessons.value = lessonsQuery.data;
  signalQuestions.value = questionsQuery.data;

  return (
    <div className="component-admin-courses">
      <PageHeader title="Kurser" icon="courses" />

      <Row>
        <Col className="col-lg-4 col-sm-12">
          {/* Courses */}
          <>
            {signalCourses.value.map(function (course, index) {
              return (
                <ListItem
                  name={course.name}
                  title={course.name}
                  key={index}
                  active={signalSelectedCourseId.value === course.id}
                  onClick={() => {
                    console.log('selected course', course.id);
                    signalSelectedQuestionId.value = null;
                    signalSelectedLessonId.value = null;
                    signalSelectedCourseId.value = course.id;
                  }}
                  onDoubleClick={(e) => handleEditCourse(e, course)}
                >
                  <Button
                    variant="outline-secondary"
                    onClick={(e) => handleEditCourse(e, course)}
                  >
                    <FontAwesomeIcon icon={faPen} />
                  </Button>
                </ListItem>
              );
            })}

            {/* Add Course */}
            <AddButton onClick={handleNewCourse} className="mt-2" />
          </>
        </Col>

        <Col className="col-lg-4 col-sm-12">
          {/* Lessons */}
          {signalSelectedCourseId.value && (
            <>
              {getCourseLessons().map(function (lesson, index) {
                return (
                  <ListItem
                    name={lesson.name}
                    title={lesson.name}
                    key={index}
                    active={signalSelectedLessonId.value === lesson.id}
                    onClick={() => {
                      //console.log('selectedLesson', lesson);
                      signalSelectedQuestionId.value = null;
                      signalSelectedLessonId.value = lesson.id;
                    }}
                    onDoubleClick={(e) => handleEditLesson(e, lesson)}
                  >
                    <Button
                      variant="outline-secondary"
                      onClick={(e) => handlePreviewLesson(e, lesson)}
                      title="Förhandsgranska"
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </Button>
                    <Button
                      variant="outline-secondary"
                      onClick={(e) => handleEditLesson(e, lesson)}
                    >
                      <FontAwesomeIcon icon={faPen} />
                    </Button>
                  </ListItem>
                );
              })}

              {/* Add Lesson */}
              <AddButton onClick={handleNewLesson} className="mt-2" />
            </>
          )}
        </Col>

        <Col className="col-lg-4 col-sm-12">
          {/* Questions */}
          {signalSelectedLessonId.value && (
            <>
              {getLessonQuestions().map(function (question, index) {
                return (
                  <ListItem
                    name={question.name}
                    title={question.name}
                    key={index}
                    active={signalSelectedQuestionId.value === question.id}
                    onClick={async () => {
                      // console.log('selectedQuestion', question);
                      await handleSelectedQuestion(question);
                    }}
                  ></ListItem>
                );
              })}

              <div className="mt-2">
                {/* Add Question */}
                <AddButton onClick={handleNewQuestion} />

                {/* Sort Questions */}
                <Button
                  onClick={handleSortQuestions}
                  variant="success"
                  title="Sortera"
                  className="ms-2"
                >
                  <FontAwesomeIcon icon={faSort} />
                </Button>
              </div>
            </>
          )}
        </Col>
      </Row>

      {/* Course Modal */}
      {signalEditedCourse.value && (
        <CourseModal onModalClose={handleCourseModalClose} />
      )}

      {/* Lesson Modal */}
      {signalEditedLesson.value && (
        <LessonModal onModalClose={handleLessonModalClose} />
      )}

      {/* Question Modal */}
      {signalEditedQuestion.value && (
        <QuestionModal onModalClose={handleQuestionModalClose} />
      )}

      {/* Question Sort Modal */}
      {sortQuestions && (
        <QuestionSortModal onModalClose={handleSortQuestionsModalClose} />
      )}
    </div>
  );
};

export default Courses;
