import React, { useEffect } from 'react';
import { useQueries, useQueryClient } from 'react-query';
import { Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { loadFont } from '../../../../../utils/font.utils';
import CopyToClipboardButton from '../../../../Shared/CopyToClipboardButton/CopyToClipboardButton';
import {
  downloadBase64,
  getFileExtension,
  getFilenameWithoutExtension,
} from '../../../../../utils/files.utils';
import { queryExternalResources } from '../../../../../api/queries/queries.customer';
import './style.scss';

const FontList = ({ editedObject }) => {
  const metadata = editedObject.metaData.font;
  const queryClient = useQueryClient();
  const results = useQueries(
    queryExternalResources(metadata.externalResourceId)
  ); // For fonts, externalResourceId is an array of ids

  const handleFontDownload = (font) => {
    const dataUrl = `data:font/${getFileExtension(font.name)};base64,${
      font.content
    }`;

    downloadBase64(dataUrl, font.name);
  };

  const handleFontRemove = async (font) => {
    if (!metadata.deleteExternalResourceId)
      metadata.deleteExternalResourceId = [];

    metadata.deleteExternalResourceId.push(font.id);
    metadata.externalResourceId = metadata.externalResourceId.filter(
      (id) => id !== font.id
    );

    queryClient.removeQueries(['externalresources', font.id], { exact: true });

    // Remove from DOM
    document.getElementById(font.id).closest('.grid-container').remove();
  };

  const isLoading = results.some((result) => result.isLoading);
  const isSuccess = results.every((result) => result.isSuccess);

  useEffect(() => {
    if (!isSuccess) return;

    async function loadFonts() {
      for (const result of results) {
        const font = result.data;
        const fontFamily = await loadFont(font.name, font.content);

        // Get "font-preview" element and set font-family
        document.getElementById(font.id).style.fontFamily = fontFamily;
      }
    }

    loadFonts();
  }, [isSuccess, results]);

  if (isLoading) {
    return <Spinner animation="border" />;
  }

  return (
    <div className="component-font-list">
      {results.map((font, index) => (
        <div className="grid-container" key={index}>
          <div className="grid-item">
            <div className="d-flex align-items-center">
              <CopyToClipboardButton
                className="me-2"
                text={`font-family: '${getFilenameWithoutExtension(
                  font.data.name
                )}';`}
              />

              <code className="font-name">
                {getFilenameWithoutExtension(font.data.name)}
              </code>
            </div>
          </div>

          <div className="grid-item text-start">
            <span id={font.data.id} className="font-preview ">
              Det här är en förhandsgranskning av fonten
            </span>
          </div>

          <div className="grid-item">
            {/* Download */}
            <Button
              variant="primary"
              className="ms-2"
              onClick={() => handleFontDownload(font.data)}
            >
              <FontAwesomeIcon icon={faDownload} />
            </Button>

            {/* Remove */}
            <Button
              variant="danger"
              className="ms-2"
              onClick={() => handleFontRemove(font.data)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FontList;
