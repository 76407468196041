import React, { useState, useEffect } from 'react';
import { Carousel, Row, Col, Spinner } from 'react-bootstrap';
import { signalLessonData, signalUserId } from '../../state/app.signals';
import { decode } from 'js-base64';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getLessonData,
  saveLessonProgress,
  showNextSlide,
  startLesson,
} from '../../utils/home/lesson';
import Slide from '../Slide/Slide';
import './style.scss';
import Timer from '../../components/Shared/Timer/Timer';
import { usePageVisibility } from '../../hooks/usePageVisibility';
import { timespanToMs } from '../../utils/utils';
import { pauseVideos } from '../../utils/home/video-player';

let userId = '7c63a6c3-1085-4c83-b683-a4a01204c9af';
let courseId = 'dbed260b-bfbe-4029-91af-fd8f0f88cc8c';
let lessonId = 'ab6cdfe5-3fe9-45ea-a9e4-a8b9cfc16269';

const Lesson = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isPageVisible = usePageVisibility();
  const [lessonPreview] = useLocalStorage('lessonPreview', null);

  const [carouselIndex, setCarouselIndex] = useState(
    lessonPreview?.activeIndex || 0
  );
  const [timeSpentArray, setTimeSpentArray] = useState(null);

  const locationState = location.state;

  if (!locationState && !lessonPreview) {
    navigate(`/`);
  }

  const loadCustomerStyle = () => {
    const styleSession = sessionStorage.getItem('css');
    if (!styleSession) return;

    console.log('Using customer style');

    // Create customer style-element in <head>
    const style = document.createElement('style');
    style.setAttribute('data-customerstyle', '1');
    style.innerHTML = decode(styleSession);
    document.head.appendChild(style);
  };

  useEffect(() => {
    loadCustomerStyle();

    const loadLesson = async () => {
      const lesson = await getLessonData(
        userId,
        courseId,
        lessonId,
        lessonPreview
      );

      console.log(
        'lesson',
        lesson.instancedQuestions.map((x) => x.timeSpentOnQuestion)
      );

      signalLessonData.value = lesson;
      startLesson(userId, lessonId, lesson);
    };

    if (locationState) {
      userId = locationState.userId;
      courseId = locationState.courseId;
      lessonId = locationState.lessonId;
    }
    signalUserId.value = userId;

    loadLesson();
  }, [lessonPreview, locationState, navigate]);

  const handleSelect = (selectedIndex, e) => {
    // console.log('selectedIndex', e);

    pauseVideos();

    saveLessonProgress(
      signalUserId.value,
      signalLessonData.value,
      carouselIndex,
      timeSpentArray
    );

    setCarouselIndex(selectedIndex);
  };

  const handleSlid = (index) => {
    // What to do when new slide is shown
    const slide = signalLessonData.value.instancedQuestions[index];
    showNextSlide(slide);
  };

  const handleTimeUpdate = (timeSpent) => {
    if (!timeSpentArray) {
      setTimeSpentArray(
        signalLessonData.value.instancedQuestions.map((x) =>
          timespanToMs(x.timeSpentOnQuestion)
        )
      );
    }

    if (!timeSpentArray) return;

    const updatedTimeSpentArray = [...timeSpentArray];
    updatedTimeSpentArray[carouselIndex] += timeSpent;
    setTimeSpentArray(updatedTimeSpentArray);

    // console.log('timeSpentArray', timeSpentArray);
  };

  return (
    <>
      {!signalLessonData.value && (
        <div>
          <Spinner animation="grow" />
        </div>
      )}

      {/* Lesson */}
      {signalLessonData.value && (
        <div id="page-lesson">
          <Timer
            activeIndex={carouselIndex}
            onTimeUpdate={handleTimeUpdate}
            isActive={isPageVisible}
          />

          {/* Frågor */}
          <Carousel
            variant="dark"
            interval={null}
            wrap={false}
            activeIndex={carouselIndex}
            onSelect={handleSelect}
            onSlid={handleSlid}
            keyboard={false}
          >
            {signalLessonData.value.instancedQuestions?.map(function (
              slide,
              index
            ) {
              return (
                <Carousel.Item key={index}>
                  <Row>
                    <Col>{/* Left Arrow */}</Col>
                    <Col xs={8}>
                      {/* Content */}
                      <Slide slide={slide} />
                    </Col>
                    <Col>{/* Right Arrow */}</Col>
                  </Row>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      )}
    </>
  );
};

export default Lesson;
