import toast from 'react-hot-toast';
import ToastHot from '../components/Shared/ToastHot/ToastHot';
import {
  signalConfirmCallback,
  signalConfirmOptions,
  signalConfirmShow,
  signalDarkMode,
  signalLoadingBar,
} from './app.signals';

// #region Toast
export function toastMessage(
  text,
  toastType = 'success',
  promiseConfiguration = null
) {
  if (!text || text.length === 0) {
    console.warn('toastMessage: text is missing');
    return;
  }

  let duration = text.length * 150;
  if (duration < 2000) duration = 2000;

  const defaultConfig = {
    style: {
      background: signalDarkMode.value ? '#333' : '#000',
      borderRadius: '6px',
      border: `1px solid red`,
      fontSize: '18px',
      color: '#fff',
      padding: '10px',
      maxWidth: 550,
    },
  };

  switch (toastType) {
    case 'success':
      defaultConfig.duration = duration;
      defaultConfig.style.borderColor = signalDarkMode.value ? '#888' : '#000';
      break;
    case 'error':
      defaultConfig.duration = duration;
      defaultConfig.style.borderColor = 'rgb(215, 82, 82)';
      break;
    case 'loading':
      defaultConfig.style.borderColor = signalDarkMode.value ? '#888' : '#000';
      break;
    case 'promise':
      defaultConfig.style.borderColor = signalDarkMode.value ? '#888' : '#000';
      break;
    default:
      break;
  }

  if (promiseConfiguration) {
    toast.promise(
      promiseConfiguration.promise,
      {
        loading: text,
        success: promiseConfiguration.successText,
        error: promiseConfiguration.errorText || 'Ett fel uppstod',
      },
      defaultConfig
    );
    return;
  }
  // console.log('toastType', toast, toastType);

  toast[toastType]((t) => <ToastHot t={t} text={text} />, defaultConfig);
}
// #endregion

// #region LoadingBar
export function increaseLoadingBarProgress(total) {
  signalLoadingBar.value = Math.min(100, signalLoadingBar.value + 100 / total);
}
// #endregion

// #region Confirm Modal
export const confirmModalOptionsDefault = {
  title: 'Lämna sidan?',
  body: 'Är du säker på att du vill lämna den aktuella sidan? Ändringarna du har gjort kommer inte att sparas.',
  cancelText: 'Avbryt',
  confirmText: 'Ok',
  confirmVariant: 'primary',
};

export const confirmModalOptionsDelete = {
  title: 'Ta bort?',
  body: 'Är du säker på att du vill ta bort?',
  confirmText: 'Ta bort',
  confirmVariant: 'danger',
};

export function confirmModal(callback, options) {
  if (options) {
    options = { ...confirmModalOptionsDefault, ...options };
  } else {
    options = confirmModalOptionsDefault;
  }

  signalConfirmCallback.value = callback;
  signalConfirmOptions.value = options;
  signalConfirmShow.value = true;
}

export function confirmModalClose() {
  signalConfirmShow.value = false;
}
// #endregion
