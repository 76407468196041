import React, { useRef } from 'react';
import { Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { apiClient, setJwtToken } from '../../api/api.client';
import { toastMessage } from '../../state/app-functions';
import logo from '../../assets/images/logo.png';
import './style.scss';
import background1 from '../../assets/backgrounds/nature1.jpg';
import background2 from '../../assets/backgrounds/nature2.jpg';
import background3 from '../../assets/backgrounds/nature3.jpg';
import background4 from '../../assets/backgrounds/nature4.jpg';
import background5 from '../../assets/backgrounds/nature5.jpg';
import background6 from '../../assets/backgrounds/nature6.jpg';
import background7 from '../../assets/backgrounds/nature7.jpg';
import background8 from '../../assets/backgrounds/nature8.jpg';
import background9 from '../../assets/backgrounds/nature9.jpg';

const Login = () => {
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);

  const images = [
    background1,
    background2,
    background3,
    background4,
    background5,
    background6,
    background7,
    background8,
    background9,
  ];
  const randomImage = images[Math.floor(Math.random() * images.length)];

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const { data } = await apiClient.post(`login`, {
        email: usernameRef.current.value.trim(),
        password: passwordRef.current.value.trim(),
      });

      // console.log('jwtToken result', data.token);
      setJwtToken(data.token);

      // We cannot use navigate() here as we need the routes to reload
      window.location.href = '/admin';
    } catch {
      setJwtToken(null);
      toastMessage('Fel användarnamn eller lösenord', 'error');
    }
  };

  return (
    <div
      className="component-login"
      style={{ backgroundImage: `url(${randomImage})` }}
    >
      <Row className="vh-100 align-items-center justify-content-center">
        <Col md="8" lg="3" sm="8" xs="10" className="login-container">
          <div className="d-flex align-items-center mb-2">
            <img src={logo} alt="logo" width="30" className="me-3" />
            <span className="login-header">Logga in</span>
          </div>

          {/* Email */}
          <FloatingLabel controlId="email" label="E-Post" className="mb-3">
            <Form.Control
              type="email"
              placeholder="name@example.com"
              onChange={(e) =>
                (usernameRef.current.value = e.target.value.trim())
              }
              autoFocus={true}
              ref={usernameRef}
            />
          </FloatingLabel>

          {/* Password */}
          <FloatingLabel controlId="password" label="Lösenord" className="mb-3">
            <Form.Control
              type="password"
              placeholder="..."
              onChange={(e) =>
                (passwordRef.current.value = e.target.value.trim())
              }
              ref={passwordRef}
            />
          </FloatingLabel>

          {/* Submit */}
          <Button
            variant="secondary"
            size="lg"
            className="w-100"
            onClick={handleLogin}
          >
            Logga in
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
