import React from 'react';
import PrettyJson from '../../components/Shared/PrettyJson/PrettyJson';
import { useApiGet } from '../../hooks/useApiGet';

function Test() {
  const userId = '7c63a6c3-1085-4c83-b683-a4a01204c9af';
  const courseId = '532f98a8-216e-4c18-9fdc-0a019a088c04';
  const lessonId = 'f25da357-8999-4d2d-b1da-45600186c121';

  const { data, error, isDone } = useApiGet(
    `Users/${userId}/courses/${courseId}/lessons/${lessonId}`
    // `Lessons`
  );

  return (
    <>
      {!isDone && !error && <div>Loading...</div>}
      {isDone && <PrettyJson data={data} />}
      {error && <PrettyJson data={error} />}
    </>
  );
}

export default Test;
