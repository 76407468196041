import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { loadExternalResources } from '../../utils/home/customer';
import { useQueries } from 'react-query';
import {
  queryCustomerDetailBinaries,
  queryInstansiatedCourse,
} from '../../api/queries/queries.customer';

function RedirectEmail() {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const guidCourse = searchParams.get('id');
  const guidUser = searchParams.get('userid');
  const guidCustomer = searchParams.get('customerid');

  const results = useQueries([
    queryCustomerDetailBinaries(guidCustomer),
    queryInstansiatedCourse(guidCourse),
  ]);
  const isSuccess = results.every((result) => result.isSuccess);
  const [customerQuery, iCourseQuery] = results;

  const load = async (customer) => {
    await loadExternalResources(customer);

    // console.log(`Courses loaded`, iCourseQuery.data);

    navigate(`/lesson/select`, {
      replace: true,
      state: {
        customerId: guidCustomer,
        userId: guidUser,
        courses: [iCourseQuery.data],
      },
    });
  };

  if (!isSuccess) return <></>;

  if (isSuccess) {
    load(customerQuery.data);
  }
}

export default RedirectEmail;
