import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ListItem from '../ListItem/ListItem';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { queryCustomers } from '../../../api/queries/queries.customer';
import {
  signalCourseName,
  signalCourseVersion,
  signalCustomerName,
} from './CustomerCoursesLayout';

const CustomerStatistics = () => {
  const { data, isLoading } = useQuery(queryCustomers());
  const navigate = useNavigate();

  signalCustomerName.value = null;
  signalCourseName.value = null;
  signalCourseVersion.value = null;

  const handleCustomerClick = async (customer) => {
    navigate(`/admin/statistics/${customer.id}`);
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <Row>
      <Col className="col-lg-6 col-sm-12">
        {/* Customers */}
        {data.map(function (customer, index) {
          if (customer.availibleCourses) {
            return (
              <ListItem
                name={customer.name}
                key={index}
                onClick={() => handleCustomerClick(customer)}
              />
            );
          }
          return null; // Or you can omit this line if you prefer
        })}
      </Col>
    </Row>
  );
};

export default CustomerStatistics;
