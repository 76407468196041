import { apiClient } from '../../api/api.client';

export const mailTemplate = {
  sendReminderDay: null, // '2024-06-28T08:19:54.930Z'
  overridePeopleToSend: [],
  overrideSendReminderDayAndSendNow: false,
};

export const sendMail = async (body) => {
  return await apiClient.post(`mail`, body);
};
