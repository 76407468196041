import { useEffect, useRef, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import logo from '../../../assets/images/logo.png';
import { signalSelectedCourseId } from '../../../state/admin-courses';
import ListItem from '../ListItem/ListItem';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useQueries } from 'react-query';
import { queryCustomers } from '../../../api/queries/queries.customer';
import { queryUsers } from '../../../api/queries/queries.user';
import { queryCourses } from '../../../api/queries/queries.course';
import { queryLessons } from '../../../api/queries/queries.lesson';
import { queryQuestions } from '../../../api/queries/queries.question';

const Search = () => {
  const results = useQueries([
    queryCourses(),
    queryLessons(),
    queryQuestions(),
    queryCustomers(),
    queryUsers(),
  ]);

  const isLoading = results.some((result) => result.isLoading);
  const isSuccess = results.every((result) => result.isSuccess);

  const inputRef = useRef(null);
  const navigate = useNavigate();

  const [showClear, setShowClear] = useState(false);
  const [searchResultsUser, setSearchResultsUser] = useState([]);
  const [searchResultsCustomer, setSearchResultsCustomer] = useState([]);
  const [searchResultsCourses, setSearchResultsCourses] = useState([]);

  const [
    coursesQuery,
    lessonsQuery,
    questionsQuery,
    customersQuery,
    usersQuery,
  ] = results;

  let tabIndex = 1;

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isSuccess]);

  const handleSearch = (e) => {
    clearSearchResults();
    const text = e.target.value;

    if (text.length > 0) setShowClear(true);

    if (text.length < 3) return;

    const textLower = text.toLowerCase();

    const users = usersQuery.data.filter(
      (x) => x.name.toLowerCase().includes(textLower) || x.id === text
    );
    const customers = customersQuery.data.filter(
      (x) => x.name.toLowerCase().includes(textLower) || x.id === text
    );
    const courses = coursesQuery.data.filter(
      (x) => x.name.toLowerCase().includes(textLower) || x.id === text
    );

    users ? setSearchResultsUser(users) : setSearchResultsUser([]);
    customers
      ? setSearchResultsCustomer(customers)
      : setSearchResultsCustomer([]);
    courses ? setSearchResultsCourses(courses) : setSearchResultsCourses([]);
  };

  const handleClear = () => {
    setShowClear(false);
    clearSearchResults();
    inputRef.current.value = '';
    inputRef.current.focus();
  };

  const clearSearchResults = () => {
    setSearchResultsUser([]);
    setSearchResultsCustomer([]);
    setSearchResultsCourses([]);
  };

  const handleSelectUser = (user) => {
    navigate(`/admin/users/${user.id}`);
  };

  const handleSelectCustomer = (customer) => {
    navigate(`/admin/customers/${customer.id}`);
  };

  const handleSelectCourse = (course) => {
    signalSelectedCourseId.value = course.id;
    navigate(`/admin/courses/`);
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <div className="component-admin-search">
      <Row className="justify-content-md-center">
        <Col xs lg="6">
          <div className="d-flex align-items-center">
            <img src={logo} alt="logo" width="100" className="me-3" />
            <h1>Sök</h1>
            <kbd className="ms-3">Control</kbd> &nbsp;+&nbsp;
            <kbd>f</kbd>
          </div>

          <div className="mt-3">
            <Form.Group className="search-group mb-3">
              <Form.Control
                id="search"
                tabIndex="1"
                type="text"
                placeholder="Sök..."
                className="search-input"
                ref={inputRef}
                onChange={handleSearch}
              />
              <FontAwesomeIcon icon={faSearch} className="search-icon" />

              {showClear && (
                <FontAwesomeIcon
                  icon={faTimes}
                  className="search-clear-icon"
                  onClick={handleClear}
                />
              )}
            </Form.Group>
          </div>
          <div className="search-results">
            {/* Customers */}
            {searchResultsCustomer.length > 0 && (
              <Card>
                <Card.Body>
                  <Card.Title>Kunder</Card.Title>
                  {searchResultsCustomer.map((customer, index) => (
                    <ListItem
                      name={`${customer.name}`}
                      tabIndex={++tabIndex}
                      key={index}
                      isSelectable={true}
                      onClick={() => handleSelectCustomer(customer)}
                    />
                  ))}
                </Card.Body>
              </Card>
            )}

            {/* Courses */}
            {searchResultsCourses.length > 0 && (
              <Card>
                <Card.Body>
                  <Card.Title>Kurser</Card.Title>
                  {searchResultsCourses.map((course, index) => (
                    <ListItem
                      name={`${course.name}`}
                      tabIndex={++tabIndex}
                      key={index}
                      isSelectable={true}
                      onClick={() => handleSelectCourse(course)}
                    />
                  ))}
                </Card.Body>
              </Card>
            )}

            {/* Users */}
            {searchResultsUser.length > 0 && (
              <Card>
                <Card.Body>
                  <Card.Title>Användare</Card.Title>
                  {searchResultsUser.map((user, index) => (
                    <ListItem
                      name={`${user.name}`}
                      title={user.email}
                      tabIndex={++tabIndex}
                      key={index}
                      isSelectable={true}
                      onClick={() => handleSelectUser(user)}
                    />
                  ))}
                </Card.Body>
              </Card>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Search;
