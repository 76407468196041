import { apiClient, handleApiError } from '../../../api/api.client';
import { userTemplate } from '../../../utils/admin/user.utils';
import { toastMessage } from '../../../state/app-functions';
import { signalEditedUser, signalUsers } from '../../../state/admin-users';

export async function getUsers() {
  try {
    const result = await apiClient.get(`users`);
    signalUsers.value = result.data;
  } catch (error) {
    handleApiError(error, 'Det gick inte att hämta användare');
  }
}

export async function saveUser(queryClient, navigate) {
  const edit = signalEditedUser.value;

  const body = {
    ...userTemplate,
    name: edit.name,
    email: edit.email,
    password: edit.password || '',
    customers: edit.customers,
    isActive: edit.isActive,
  };

  console.log('save user', body);

  try {
    let resultCreate;

    if (edit.id !== userTemplate.id) {
      delete body.id;
      delete body.type;
      delete body.password;
      delete body.customers;
      body.nameChanged = true;
      body.emailChanged = true;
      // body.passwordChanged = false;
      body.isActiveChanged = true;
      // body.customersChanged = true;
      // console.log('patch body', body);

      // Update
      const resultUpdate = await apiClient.patch(`user/${edit.id}`, body);
      console.log('update', resultUpdate.data);
    } else {
      // Create
      resultCreate = await apiClient.post(`user`, body);
      console.log('user created', resultCreate.data);
    }

    // Delete user customers
    if (edit.customersRemove?.length > 0) {
      for (const customerid of edit.customersRemove) {
        await apiClient.delete(`user/${edit.id}/customer/${customerid}`);
        edit.customers = edit.customers.filter((id) => id !== customerid);
      }

      delete signalEditedUser.value.customersRemove;
    }

    // Add user customers
    if (edit.customersAdd?.length > 0) {
      for (const customerid of edit.customersAdd) {
        await apiClient.post(`user/${edit.id}/customer/${customerid}`);
        edit.customers.push(customerid);
      }

      delete signalEditedUser.value.customersAdd;
    }

    delete signalEditedUser.value.isDirty;

    queryClient.removeQueries(['users', 'detail', edit.id]);
    queryClient.removeQueries(['users'], { exact: true });

    toastMessage('Användaren sparad');

    if (resultCreate) {
      navigate(`/admin/users/${resultCreate.data.id}`, {
        replace: true,
      });
    }
  } catch (error) {
    handleApiError(error, 'Användaren kunde inte sparas');
  }
}

export async function deleteUser(id, queryClient) {
  try {
    queryClient.removeQueries(['users', 'detail', id]);
    await apiClient.delete(`user/${id}`);
    await queryClient.invalidateQueries(['users'], { exact: true });

    toastMessage('Användaren raderad');
  } catch (error) {
    handleApiError(error, 'Användaren kunde inte raderas');
  }
}

export function getUserCourses(state, stateCourses) {}
