import './style.scss';

const ErrorBoundaryFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div className="component-errorbounary">
      <p>Nu gick något snett:</p>
      <pre>{error?.message}</pre>
      <button onClick={resetErrorBoundary}>Försök igen</button>
    </div>
  );
};

export default ErrorBoundaryFallback;
