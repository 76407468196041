import axios from 'axios';
import { toastMessage } from '../state/app-functions';
import { signalLoadingBarRef } from '../state/app.signals';

export const apiClient = axios.create({
  baseURL: '/api',
  timeout: 20000,
  headers: {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
    Accept: 'application/json',
  },
});

// Request
apiClient.interceptors.request.use(
  (config) => {
    signalLoadingBarRef.value?.continuousStart();

    const consoleStyle = getMethodStyle(config.method);

    console.log(
      `%c${config.method.toUpperCase()}`,
      consoleStyle,
      `${config.baseURL}/${config.url}`
    );

    // Set the Authorization header with the JWT token
    let token = getJwtToken();
    if (token) config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response
apiClient.interceptors.response.use(
  function (response) {
    // console.log('Response received:', response);

    signalLoadingBarRef.value?.complete();

    return response;
  },
  function (error) {
    signalLoadingBarRef.value?.complete();
    return Promise.reject(error);
  }
);

export const getJwtToken = () => {
  const token = localStorage.getItem('jwtToken');
  return token;
};

export const setJwtToken = (token) => {
  if (!token || token.length === 0) {
    localStorage.removeItem('jwtToken');
    return;
  }

  localStorage.setItem('jwtToken', token);
};

export function handleApiError(error, fallbackText) {
  console.log(
    '%capiError',
    'background: orangered; color: white; display: block; padding: 0 5px',
    fallbackText,
    error
  );

  if (!error) {
    console.warn('Error object is null');
    toastMessage(fallbackText, 'error');
  }

  if (!error.response) {
    console.warn('error.response is null', error);
    toastMessage(fallbackText, 'error');
    return;
  }

  let apiErrorTitle = 'Title not set';
  let apiErrorMessage = 'Message not set';

  [apiErrorTitle, apiErrorMessage] = getResponseError(error.response);

  toastMessage(`${apiErrorTitle} ${apiErrorMessage}`, 'error');
  // throw new Error('Api error');
}

function getResponseError(response) {
  console.log('getResponseError()', response);

  try {
    if (response.status === 404) return ['404: Not found', ''];
    if (response.status === 405) return ['405: Method not allowed', ''];

    if (typeof response.data === 'string') {
      return [response.data, ''];
    }

    const title = response.data.title;
    let message = '';

    if (response.data.errors) {
      message = JSON.stringify(response.data.errors);
    }

    return [title, message];
  } catch (error) {
    console.warn('Try-catch exeption', error);
  }

  return ['Could not get error details', ''];
}

const getMethodStyle = (method) => {
  method = method.toUpperCase();

  let consoleStyle = 'padding: 2px 4px; color:white; border-radius: 3px;';

  switch (method) {
    case 'GET':
      consoleStyle += 'background-color: #61affe;';
      break;
    case 'POST':
      consoleStyle += 'background-color: #49cc90;';
      break;
    case 'PUT':
      consoleStyle += 'background-color: #fca130;';
      break;
    case 'PATCH':
      consoleStyle += 'background-color: #50e3c2;';
      break;
    case 'DELETE':
      consoleStyle += 'background-color: #f93e3e;';
      break;
    default:
      consoleStyle += 'background-color: black;';
      break;
  }

  return consoleStyle;
};
