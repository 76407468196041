export const YOUTUBE_PLAYER_COMMAND = Object.freeze({
  PLAY: `{"event":"command","func":"playVideo","args":""}`,
  STOP: `{"event":"command","func":"stopVideo","args":""}`,
  PAUSE: `{"event":"command","func":"pauseVideo","args":""}`,
});

export function pauseVideos() {
  const iframes = document.querySelectorAll('iframe.youtube-player');

  iframes.forEach((iframe) => {
    iframe.contentWindow.postMessage(YOUTUBE_PLAYER_COMMAND.PAUSE, '*');
  });
}
