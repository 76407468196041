import React, { useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { saveAnswer, showNextSlide } from '../../../utils/home/lesson';
import { toastMessage } from '../../../state/app-functions';
import Answer from '../Answer/Answer';
import {
  signalDarkMode,
  signalLessonData,
  signalUserId,
} from '../../../state/app.signals';
import './style.scss';
import { handleApiError } from '../../../api/api.client';

const YesNo = ({ slide }) => {
  const SVAR_YES = '1';
  const SVAR_NO = '0';

  const [hasAnswered, setHasAnswered] = useState(slide.hasAnswered);
  const [isYesActive, setYesActive] = useState(
    slide.answers === null ? false : slide.answers[0] === SVAR_YES
  );
  const [isNoActive, setNoActive] = useState(
    slide.answers === null ? false : slide.answers[0] === SVAR_NO
  );

  const handleOnClick = async (e) => {
    const answer = e.target.value;

    slide.answers = [answer];
    setNoActive(answer === SVAR_NO);
    setYesActive(answer === SVAR_YES);

    if (signalLessonData.value.isPreview) {
      toastMessage(
        'Ditt svar är inte sparat eftersom detta är en förhandsgranskning'
      );
      setHasAnswered(true);
      showNextSlide(slide, true);
      return;
    }

    try {
      await saveAnswer(signalUserId.value, slide);
    } catch (error) {
      handleApiError(error, 'Ett fel uppstod när ditt svar skulle sparas');
      return;
    }

    setHasAnswered(true);
    showNextSlide(slide, true);
  };

  return (
    <div className="component-fraga-yesno">
      <ButtonGroup size="lg">
        <Button
          variant={
            isYesActive === true
              ? 'success'
              : signalDarkMode.value
              ? 'outline'
              : 'light'
          }
          onClick={handleOnClick}
          value={SVAR_YES}
        >
          Ja
        </Button>
        <Button
          variant={
            isNoActive === true
              ? 'danger'
              : signalDarkMode.value
              ? 'outline'
              : 'light'
          }
          onClick={handleOnClick}
          value={SVAR_NO}
        >
          Nej
        </Button>
      </ButtonGroup>

      <Answer slide={slide} hasAnswered={hasAnswered} />
    </div>
  );
};

export default YesNo;
