import React, { useState } from 'react';
import { useSignal } from '@preact/signals-react';
import { Button, Offcanvas } from 'react-bootstrap';
import arrayMove from 'array-move';
import {
  getSelectedLesson,
  getLessonQuestions,
  updateLessonQuestions,
} from '../Courses/utils';
import SortableList, { SortableItem } from 'react-easy-sort';
import './style.scss';

const QuestionSortModal = ({ onModalClose }) => {
  const [items, setItems] = useState(getLessonQuestions());

  const showModal = useSignal(true);

  const onSortEnd = (oldIndex, newIndex) => {
    setItems((array) => arrayMove(array, oldIndex, newIndex));
  };

  const closeModal = () => {
    showModal.value = false;
    onModalClose();
  };

  const handleCloseModal = () => {
    closeModal();
  };

  // Save
  const handleSave = () => {
    const selectedLesson = getSelectedLesson();
    selectedLesson.questions = items.map((item) => item.id);
    updateLessonQuestions(selectedLesson);
    closeModal();
  };

  return (
    <div className="component-question-sort-container">
      {/* Offcanvas */}
      <Offcanvas
        show={showModal}
        onHide={handleCloseModal}
        placement="end"
        className="modal-sort-question-width"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className="d-flex align-items-center">
              Sortera frågor
              {/* Save Button */}
              <Button variant="primary" className="ms-4" onClick={handleSave}>
                Spara
              </Button>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <SortableList
            onSortEnd={onSortEnd}
            className="sortable-list"
            draggedItemClassName="dragged"
          >
            {items.map((item, index) => (
              <SortableItem key={index}>
                <div className="sortable-question text-truncate">
                  {item.name}
                </div>
              </SortableItem>
            ))}
          </SortableList>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default QuestionSortModal;
