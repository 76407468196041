import { apiClient } from '../api.client';

export const queryQuestions = () => {
  return {
    queryKey: ['questions'],
    queryFn: async () => {
      const result = await apiClient.get(`questions`);
      return result.data;
    },
  };
};
