import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import ListItem from '../ListItem/ListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const LessonQuestionsModal = ({ lesson, onModalClose }) => {
  const handleQuestionClick = (question) => {
    console.log('question', question);
  };

  if (!lesson) return null;

  const statistics = lesson.questionStatistic;

  return (
    <Modal size="lg" centered show={lesson !== null} onHide={onModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>{lesson.name}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {statistics.question.map((question, index) => (
          <ListItem
            key={index}
            name={
              <div className="d-flex w-100">
                <div>{question.name}</div>

                <div className="ms-auto d-flex align-items-center">
                  {question.hasAnswered === true && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="me-2 text-success"
                    />
                  )}
                  <div className="text-muted">
                    {question.timeSpentOnQuestion}
                  </div>
                </div>
              </div>
            }
            isSelectable={false}
            onClick={() => handleQuestionClick(question)}
          ></ListItem>
        ))}
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={onModalClose}>Stäng</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LessonQuestionsModal;
