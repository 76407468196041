import React from 'react';
import { useSignal, useSignalEffect } from '@preact/signals-react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Dropdown,
  FloatingLabel,
  Form,
  Offcanvas,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import EditableContent from '../../Shared/EditableContent/EditableContent';
import { lessonTemplate } from '../../../utils/admin/course.utils';
import { deleteLesson, saveLesson, previewLesson } from '../Courses/utils';
import {
  confirmModal,
  confirmModalOptionsDelete,
} from '../../../state/app-functions';
import './style.scss';
import { signalEditedLesson } from '../../../state/admin-courses';
import DropdownCopyGuid from '../DropdownCopyGuid';
import { useQueryClient } from 'react-query';

const LessonModal = ({ onModalClose }) => {
  const editedObject = signalEditedLesson.value;
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const showModal = useSignal(true);
  const objectName = useSignal(editedObject.name);
  const objectActive = useSignal(editedObject.isActive);
  const objectDescription = useSignal(editedObject.description);

  useSignalEffect(() => {
    editedObject.name = objectName.value;
    editedObject.isActive = objectActive.value;
    editedObject.description = objectDescription.value;
  });

  const closeModal = () => {
    showModal.value = false;
    onModalClose();
  };

  const handleCloseModal = () => {
    if (editedObject.isDirty) {
      confirmModal(closeModal);
      return;
    }

    closeModal();
  };

  // Save
  const handleSave = () => {
    saveLesson(queryClient);
    // closeModal();
  };

  // Delete
  const handleDelete = () => {
    confirmModal(() => {
      deleteLesson(queryClient);
      closeModal();
    }, confirmModalOptionsDelete);
  };

  // Preview Lesson
  const handlePreviewLesson = async () => {
    await previewLesson(navigate, editedObject.id);
  };

  return (
    <div id="component-lesson-container">
      {/* Offcanvas */}
      <Offcanvas
        show={showModal}
        onHide={handleCloseModal}
        placement="bottom"
        className="modal-lesson-height"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className="d-flex align-items-center">
              {/* Name */}
              <EditableContent
                className="me-4"
                value={objectName.value}
                onChange={(text) => {
                  editedObject.isDirty = true;
                  objectName.value = text;
                }}
              />

              {/* Aktiv */}
              <Form.Check
                type="switch"
                id="aktiv-switch"
                className={objectActive.value ? 'text-success' : 'text-muted'}
                label={objectActive.value ? 'Aktiv' : 'Inaktiv'}
                checked={objectActive.value}
                onChange={(e) => {
                  editedObject.isDirty = true;
                  objectActive.value = e.target.checked;
                }}
              />

              {/* Actions */}
              {editedObject.id !== lessonTemplate.id && (
                <Dropdown>
                  <Dropdown.Toggle variant="secondary"></Dropdown.Toggle>

                  <Dropdown.Menu>
                    <DropdownCopyGuid guid={editedObject.id} />
                  </Dropdown.Menu>
                </Dropdown>
              )}

              {/* Förhandsgranska */}
              <Button
                variant="secondary"
                className="ms-4"
                title="Förhandsgranska"
                onClick={handlePreviewLesson}
              >
                <FontAwesomeIcon icon={faEye} />
              </Button>

              {/* Save Button */}
              <Button variant="primary" className="ms-4" onClick={handleSave}>
                Spara
              </Button>

              {/* Delete Button */}
              {editedObject.id !== lessonTemplate.id && (
                <Button
                  variant="danger"
                  className="ms-4"
                  onClick={handleDelete}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              )}
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* Description */}
          <FloatingLabel label="Beskrivning" className="mt-3">
            <Form.Control
              type="text"
              value={objectDescription.value || ''}
              onChange={(e) => {
                editedObject.isDirty = true;
                objectDescription.value = e.target.value;
              }}
            />
          </FloatingLabel>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default LessonModal;
