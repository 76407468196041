import { Navigate } from 'react-router-dom';
import LayoutLesson from '../containers/Layout/LayoutLesson';
import Lesson from '../pages/Lesson/Lesson';
import LessonSelect from '../pages/LessonSelect/LessonSelect';
import Login from '../pages/Login/Login';
import RedirectEmail from '../pages/Redirect/RedirectEmail';
import RedirectPreviewLesson from '../pages/Redirect/RedirectPreviewLesson';
import End from '../pages/Slide/End/End';
import Dnd from '../pages/Test/Dnd';
import Test from '../pages/Test/Test';
import Weather from '../pages/Test/Weather';
import Welcome from '../pages/Welcome/Welcome';
import Chart from '../pages/Test/Chart';

export default function publicRoutes() {
  //   const queryClient = new QueryClient();

  return [
    { path: '/', element: <Welcome /> },
    {
      path: '/lesson',
      element: <LayoutLesson />,
      children: [
        { path: 'show', element: <Lesson /> },
        { path: 'select', element: <LessonSelect /> },
      ],
    },
    { path: '/login', element: <Login /> },
    { path: '/instancedcourse', element: <RedirectEmail /> },
    { path: '/previewlesson', element: <RedirectPreviewLesson /> },
    { path: '/test', element: <Test /> },
    { path: '/end', element: <End /> },
    { path: '/chart', element: <Chart /> },
    { path: '/dnd', element: <Dnd /> },
    { path: '/weather', element: <Weather /> },
    { path: '*', element: <Navigate to="/login" replace /> },
  ];
}
