import React from 'react';
import Multi from '../Fragetyp/Multi';
import NumberInput from '../Fragetyp/NumberInput';
import TextInput from '../Fragetyp/TextInput';
import YesNo from '../Fragetyp/YesNo';
import ErrorBoundaryFallback from '../../Shared/ErrorBoundaryFallback/ErrorBoundaryFallback';
import { ErrorBoundary } from 'react-error-boundary';
import { FRAGETYP } from '../../../utils/constants';
import Radio from '../Fragetyp/Radio';
import './style.scss';

const Fraga = ({ slide }) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorBoundaryFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="mt-3">
        {slide.type === FRAGETYP.YESNO && <YesNo slide={slide} />}
        {slide.type === FRAGETYP.NUMBER && <NumberInput slide={slide} />}
        {slide.type === FRAGETYP.TEXT && <TextInput slide={slide} />}
        {slide.type === FRAGETYP.MULTI && <Multi slide={slide} />}
        {slide.type === FRAGETYP.RADIO && <Radio slide={slide} />}
      </div>
    </ErrorBoundary>
  );
};

export default Fraga;
