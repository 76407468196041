import { useLocation, useNavigate } from 'react-router-dom';
import { apiClient } from '../../api/api.client';
import { useQuery } from 'react-query';
import { loadExternalResources } from '../../utils/home/customer';
import { removeCustomerStyles } from '../../utils/admin/common.utils';

function RedirectPreviewLesson() {
  removeCustomerStyles();

  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state;
  const customerId = locationState.customerId; // Can also be "none"

  const getCustomerData = async (guidCustomer) => {
    if (guidCustomer === 'none') return 'none';

    const { data } = await apiClient.get(
      `customer/${guidCustomer}/?binaries=true`
    );
    return data;
  };

  const loadAndApplyResources = async (customer) => {
    if (customer !== 'none') {
      await loadExternalResources(customer);
    }

    // Q&D fix. Otherwise we get "Shoud have a queue" error
    setTimeout(() => {
      navigate(`/lesson/show`, {
        replace: true,
      });
    }, 10);
  };

  const { data, isSuccess } = useQuery(
    ['customers', 'detail', customerId, 'binaries'],
    () => getCustomerData(customerId)
  );

  if (isSuccess) loadAndApplyResources(data);
}

export default RedirectPreviewLesson;
