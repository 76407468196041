import { useQuery } from 'react-query';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { queryInstansiatedCourseStatistics } from '../../../api/queries/queries.customer';
import ListItem from '../ListItem/ListItem';
import { useState } from 'react';
import { signalCourseName, signalCourseVersion } from './CustomerCoursesLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import LessonQuestionsModal from './LessonQuestionsModal';

const CustomerCourseStatistics = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { data: course, isSuccess } = useQuery(
    queryInstansiatedCourseStatistics(params.courseId)
  );

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);

  const handleUserClick = (user) => {
    setSelectedUser(user);
    console.log('selected user', user);
  };

  const handleGotoUser = (user) => {
    navigate(`/admin/users/${user.id}`);
  };

  const handleLessonClick = (lesson) => {
    setSelectedLesson(lesson);
  };

  const handleCloseModal = () => {
    setSelectedLesson(null);
  };

  if (!isSuccess) return <></>;

  signalCourseName.value = course.statistics.name;
  signalCourseVersion.value = course.statistics.courseVersion;

  return (
    <Row>
      <Col lg="6">
        {!course.users && <div>Inga användare finns tilldelad denna kurs</div>}

        {/* Users */}
        {course.users?.map((user, index) => {
          const completedLessons =
            user.instancedCourseStatistics.numberOfCompletedLessons;
          const totalLessons =
            completedLessons +
            user.instancedCourseStatistics.numberOfIncompletedLessons;

          const lessonPercentage = Math.floor(
            (user.instancedCourseStatistics.numberOfCompletedLessons /
              totalLessons) *
              100
          );

          return (
            <ListItem
              key={user.id}
              active={selectedUser?.id === user.id}
              name={
                <div className="d-flex w-100">
                  <span className="me-auto">{user.name}</span>
                  <span
                    className={lessonPercentage === 100 ? 'text-success' : ''}
                  >
                    {lessonPercentage}% ({completedLessons}/{totalLessons})
                  </span>
                </div>
              }
              onClick={() => handleUserClick(user)}
            >
              <Button
                variant="outline-secondary"
                onClick={() => handleGotoUser(user)}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </ListItem>
          );
        })}
      </Col>

      {/* Lessons */}
      {selectedUser && (
        <Col lg="6">
          {selectedUser.instancedCourseStatistics.lessonStatistics.map(
            (lesson, index) => {
              const completedQuestions =
                lesson.questionStatistic.numberOfCompletedQuestions;
              const totalQuestions =
                completedQuestions +
                lesson.questionStatistic.numberOfIncompletedQuestions;
              const questionPercentage = Math.floor(
                (completedQuestions / totalQuestions) * 100
              );

              return (
                <ListItem
                  key={lesson.id}
                  active={selectedLesson?.id === lesson.id}
                  name={
                    <div className="d-flex w-100">
                      <span className="me-auto">{lesson.name}</span>
                      <span
                        className={
                          questionPercentage === 100 ? 'text-success' : ''
                        }
                      >
                        {questionPercentage}% ({completedQuestions}/
                        {totalQuestions})
                      </span>
                    </div>
                  }
                  onClick={() => handleLessonClick(lesson)}
                ></ListItem>
              );
            }
          )}
        </Col>
      )}

      <LessonQuestionsModal
        lesson={selectedLesson}
        onModalClose={handleCloseModal}
      />
    </Row>
  );
};

export default CustomerCourseStatistics;
