import {
  faBook,
  faBuilding,
  faChartLine,
  faFileAlt,
  faQuestion,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Row } from 'react-bootstrap';

const PageHeader = ({ icon, title, children }) => {
  const getIcon = (icon) => {
    switch (icon) {
      case 'users':
        return faUser;
      case 'customers':
        return faBuilding;
      case 'courses':
        return faBook;
      case 'lessons':
        return faFileAlt;
      case 'statistics':
        return faChartLine;
      default:
        return faQuestion;
    }
  };

  return (
    <Row className="mb-3">
      <Col>
        <Card>
          <Card.Body className="dl-card-header">
            <div className="icon-container">
              <FontAwesomeIcon icon={getIcon(icon)} />
            </div>
            {title}
            {children}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default PageHeader;
