import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Fraga from '../../components/Home/Fraga/Fraga';
import HtmlDiv from '../../components/Shared/HtmlDiv/HtmlDiv';
import SlideMedia from '../../components/Home/SlideMedia/SlideMedia';
import './style.scss';
import { FRAGETYP } from '../../utils/constants';
import End from './End/End';

const Slide = ({ slide }) => {
  return (
    <div className="component-lesson-slide">
      {slide.type !== FRAGETYP.END && (
        <Row>
          <Col>
            <h1 className="slide-title">{slide.name}</h1>
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          {slide.attachmentContent && <SlideMedia slide={slide} />}

          {slide.type === FRAGETYP.END ? (
            <End slide={slide} />
          ) : (
            <HtmlDiv className="slide-body">{slide.description}</HtmlDiv>
          )}

          <Fraga slide={slide} />
        </Col>
      </Row>
    </div>
  );
};

export default Slide;
