import PageHeader from '../PageHeader';
import { Row } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
const { signal } = require('@preact/signals-react');

export const signalCustomerName = signal(null);
export const signalCourseName = signal(null);
export const signalCourseVersion = signal(null);

const CustomerCoursesLayout = () => {
  return (
    <div>
      <PageHeader icon="statistics">
        <span>Statistik</span>

        {signalCustomerName.value && (
          <>
            <span className="ps-2 pe-2 text-muted">&gt;</span>
            <span>{signalCustomerName}</span>
            <span className="ps-2 text-muted">&gt;</span>
            <span className="ps-2"> Kurser</span>
          </>
        )}

        {signalCourseName.value && (
          <>
            <span className="ps-2 text-muted">&gt;</span>
            <span className="ps-2">
              {signalCourseName} v{signalCourseVersion}
            </span>
          </>
        )}
      </PageHeader>

      <Row>
        <Outlet />
      </Row>
    </div>
  );
};

export default CustomerCoursesLayout;
