import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import Editor from '@monaco-editor/react';
import { encode, decode } from 'js-base64';
import FontList from './FontList/FontList';
import ImageUpload from '../../../Shared/ImageUpload/ImageUpload';
import CourseList from './CourseList/CourseList';
import UserList from './UserList/UserList';
import TabHeader from '../../../Shared/TabHeader/TabHeader';
import { signalDarkMode } from '../../../../state/app.signals';
import { signalEditedCustomer } from '../../../../state/admin-customers';
import { customerTemplate } from '../../../../utils/admin/customer.utils';
import { getApprovedEmailAsObject } from '../../Customers/utils';
import {
  EDITOR_CSS_DEFAULT_CONTENT,
  STYLE_EDITOR_CONFIG,
} from './editor.config';
import { Col, FloatingLabel, Form, Row, Tab, Tabs } from 'react-bootstrap';
import {
  getBase64FromDataUrl,
  getFileUploadObject,
} from '../../../../utils/files.utils';
import './style.scss';
import {
  queryExternalResources,
  queryInstansiatedCourses,
} from '../../../../api/queries/queries.customer';
import { useQueries } from 'react-query';

const Customer = ({ editedObject, users, courses }) => {
  const results = useQueries(
    queryExternalResources([
      editedObject.metaData.style.externalResourceId,
      editedObject.metaData.logoLight.externalResourceId,
      editedObject.metaData.logoDark.externalResourceId,
      editedObject.metaData.favicon.externalResourceId,
    ])
  );

  const resultIC = useQueries(
    queryInstansiatedCourses(editedObject.availibleCourses || [])
  );

  const [styleQuery, logoLightQuery, logoDarkQuery, faviconQuery] = results;

  const isSuccess = results.every((result) => result.isSuccess);
  const isSuccessIC = resultIC.every((result) => result.isSuccess);

  const [orgnummer, setOrgnummer] = useState(editedObject.organizationNumber);
  const [value, setValue] = useState(getApprovedEmailAsObject(editedObject));
  const [editorContent, setEditorContent] = useState(
    EDITOR_CSS_DEFAULT_CONTENT
  );
  const [logoLightBase64, setLogoLightBase64] = useState(null);
  const [logoDarkBase64, setLogoDarkBase64] = useState(null);
  const [faviconBase64, setFaviconBase64] = useState(null);
  const customerUsers = users.filter((user) =>
    user.customers.includes(editedObject.id)
  );

  const handleOrgnummerChange = (e) => {
    const orgnummer = e.target.value;
    setOrgnummer(orgnummer);
    signalEditedCustomer.value.organizationNumber = orgnummer;
    signalEditedCustomer.value.isDirty = true;
  };

  const handleApprovedEmailChange = (newValue) => {
    setValue(newValue);
    signalEditedCustomer.value.approvedEmailSuffixes = newValue.map(
      (obj) => obj.label
    );
    signalEditedCustomer.value.isDirty = true;
  };

  const handleEditorChange = (value, event) => {
    signalEditedCustomer.value.metaData.style.content = value
      ? encode(value)
      : null;
    signalEditedCustomer.value.isDirty = true;
    // console.log('metadata:', editedObject.metaData);
  };

  const handleLogoChangeLight = (imageList) => {
    const property = signalEditedCustomer.value.metaData.logoLight;
    handleLogoChange(imageList, property);
  };

  const handleLogoChangeDark = (imageList) => {
    const property = signalEditedCustomer.value.metaData.logoDark;
    handleLogoChange(imageList, property);
  };

  const handleFaviconChange = (imageList) => {
    const property = signalEditedCustomer.value.metaData.favicon;
    handleLogoChange(imageList, property);
  };

  const handleLogoChange = (imageList, property) => {
    const image = imageList[0];

    property.content = null;
    property.name = null;

    signalEditedCustomer.value.isDirty = true;

    // Add image
    if (image) {
      property.content = getBase64FromDataUrl(image.data_url);
      property.name = image.file.name;
      return;
    }
  };

  const handleFontChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const fileObject = await getFileUploadObject(file);
      // console.log('fileObject', fileObject);

      signalEditedCustomer.value.metaData.font.content = fileObject.base64;
      signalEditedCustomer.value.metaData.font.name = file.name;
      signalEditedCustomer.value.isDirty = true;

      // console.log('metadata:', editedObject.metaData.font.content.length);
    }
  };

  const handleTabSelect = async (selectedKey) => {
    switch (selectedKey) {
      // Logo
      case 'logo':
        setLogoLightBase64(logoLightQuery.data.content || null);
        setLogoDarkBase64(logoDarkQuery.data.content || null);
        setFaviconBase64(faviconQuery.data.content || null);

        break;
      // Style
      case 'style':
        if (styleQuery.data.content)
          setEditorContent(decode(styleQuery.data.content));
        break;
      // Font
      case 'font':
        // Font is handled by its own component
        break;
      default:
        // console.log('default');
        return;
    }
  };

  return (
    <Tabs variant="tabs" className="mb-3" onSelect={handleTabSelect}>
      {/* Information */}
      <Tab eventKey="info" title="Information">
        <Row>
          <Col className="col-lg-6 col-sm-12">
            {/* Organisationsnummer */}
            <FloatingLabel label="Organisationsnummer" className="mb-3">
              <Form.Control
                type="text"
                placeholder="Organisationsnummer"
                value={orgnummer || ''}
                onChange={handleOrgnummerChange}
              />
            </FloatingLabel>

            {/* Organisationsnummer */}
            <div className="dl-header mb-1">Godkända Epost-domäner</div>
            <CreatableSelect
              isMulti
              isClearable
              value={value}
              onChange={handleApprovedEmailChange}
              className="react-select"
              placeholder="Godkända Epost-domäner"
            />
          </Col>
        </Row>
      </Tab>

      {/* Style */}
      {editedObject.id !== customerTemplate.id && (
        <Tab
          eventKey="style"
          disabled={!isSuccess}
          title={
            <TabHeader
              hasContent={editedObject.metaData.style.externalResourceId}
            >
              Stil
            </TabHeader>
          }
        >
          <Editor
            height="70vh"
            defaultLanguage="css"
            theme={signalDarkMode.value ? 'vs-dark' : 'light'}
            defaultValue={editorContent}
            value={editorContent}
            options={STYLE_EDITOR_CONFIG}
            onChange={handleEditorChange}
          />
        </Tab>
      )}

      {/* Font */}
      {editedObject.id !== customerTemplate.id && (
        <Tab
          eventKey="font"
          title={
            <TabHeader
              hasContent={
                editedObject.metaData.font.externalResourceId?.length > 0
              }
            >
              Font
            </TabHeader>
          }
        >
          <Form.Group controlId="formFileLg" className="mb-3">
            <Form.Control
              type="file"
              size="lg"
              accept=".woff, .woff2, .ttf"
              onChange={handleFontChange}
            />
          </Form.Group>

          {/* Font list */}
          <FontList editedObject={editedObject} />
        </Tab>
      )}

      {/* Logo */}
      {editedObject.id !== customerTemplate.id && (
        <Tab
          className="customer-logo-tab"
          eventKey="logo"
          disabled={!isSuccess}
          title={
            <TabHeader
              hasContent={
                editedObject.metaData.favicon.externalResourceId ||
                editedObject.metaData.logoLight.externalResourceId ||
                editedObject.metaData.logoDark.externalResourceId
              }
            >
              Logo
            </TabHeader>
          }
        >
          <Row>
            <Col>
              <div className="dl-header">Light mode</div>
              <ImageUpload
                className="upload-logo light"
                imageName="LogoLight.png"
                imageBase64={logoLightBase64}
                uploadResizeMaxPixels={500}
                confirmOnRemove={true}
                onChange={handleLogoChangeLight}
              />
            </Col>
            <Col>
              <div className="dl-header">Dark mode</div>
              <ImageUpload
                className="upload-logo dark"
                imageName="LogoDark.png"
                imageBase64={logoDarkBase64}
                uploadResizeMaxPixels={500}
                confirmOnRemove={true}
                onChange={handleLogoChangeDark}
              />
            </Col>
            <Col>
              <div className="dl-header">Favicon</div>
              <ImageUpload
                className="upload-logo"
                imageName="favicon.png"
                imageBase64={faviconBase64}
                uploadResizeMaxPixels={100}
                confirmOnRemove={true}
                onChange={handleFaviconChange}
              />
            </Col>
          </Row>
        </Tab>
      )}

      {/* Users */}
      {editedObject.id !== customerTemplate.id && (
        <Tab
          eventKey="users"
          title={
            <TabHeader hasContent={customerUsers.length > 0}>
              Användare
            </TabHeader>
          }
        >
          <UserList users={customerUsers} />
        </Tab>
      )}

      {/* Courses */}
      {editedObject.id !== customerTemplate.id && (
        <Tab
          eventKey="courses"
          disabled={!isSuccessIC}
          title={
            <TabHeader hasContent={editedObject.availibleCourses?.length > 0}>
              Kurser
            </TabHeader>
          }
        >
          <Row>
            <Col>
              {isSuccessIC && (
                <CourseList
                  editedObject={editedObject}
                  users={users}
                  courses={courses}
                  iCourses={resultIC.map((result) => result.data)}
                />
              )}
            </Col>
          </Row>
        </Tab>
      )}
    </Tabs>
  );
};

export default Customer;
