import { useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import ListItem from '../../../ListItem/ListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import {
  confirmModal,
  confirmModalOptionsDefault,
  toastMessage,
} from '../../../../../state/app-functions';
import { sendMail } from '../../../../../utils/admin/mail.utils';

const UserList = ({ users }) => {
  const navigate = useNavigate();

  const [mailLogg, setMailLogg] = useState(null);

  const handleSelectUser = (user) => {
    navigate(`/admin/users/${user.id}`);
  };

  const sendMailToAllUsers = async () => {
    setMailLogg(null);

    const body = {
      overridePeopleToSend: users.map((user) => user.id),
      overrideSendReminderDayAndSendNow: true,
    };

    const mailPromise = new Promise(async (resolve, reject) => {
      try {
        const result = await sendMail(body);
        console.log('mail result', result.data);
        setMailLogg(result.data?.logg);
        resolve();
      } catch (error) {
        reject(error);
      }
    });

    toastMessage('Skickar e-post...', 'promise', {
      promise: mailPromise,
      successText: 'E-post skickad',
      errorText: 'Kunde inte skicka e-post',
    });
  };

  const handleSendMailToAllUsers = () => {
    confirmModal(
      () => {
        sendMailToAllUsers();
      },
      {
        ...confirmModalOptionsDefault,
        title: 'Skicka e-post?',
        body: 'Är du säker på att du vill skicka kursinbjudan till kundens alla användare?',
      }
    );
  };

  return (
    <Row>
      <Col>
        <p>
          Gå till en <Link to="/admin/users">användare</Link> för att ange
          kund-tilldelning.
        </p>

        {/* Send mail to all users */}
        {users.length > 0 && (
          <div>
            <Button className="mb-2" onClick={() => handleSendMailToAllUsers()}>
              <FontAwesomeIcon icon={faMailBulk} className="pe-2" />
              Skicka kursinbjudan till alla användare
            </Button>
          </div>
        )}

        {/* Course users */}
        {users.map((user, index) => (
          <ListItem name={`${user.name}`} key={index} isSelectable={false}>
            <Button
              variant="outline-secondary"
              onClick={() => handleSelectUser(user)}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </ListItem>
        ))}
      </Col>
      <Col>
        {/* Mail logg */}
        {mailLogg && (
          <Card>
            <Card.Body>
              <Card.Title>
                <div className="d-flex align-items-center">
                  <div>Mail logg</div>
                  <Button
                    variant="link"
                    className="ms-auto me-2"
                    onClick={() => setMailLogg(null)}
                  >
                    Stäng
                  </Button>
                </div>
              </Card.Title>

              <ul>
                {mailLogg.map((item, index) => (
                  <li key={index}>{item.description}</li>
                ))}
              </ul>
            </Card.Body>
          </Card>
        )}
      </Col>
    </Row>
  );
};

export default UserList;
