import { useEffect, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import ListItem from '../../../ListItem/ListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { signalEditedUser } from '../../../../../state/admin-users';
import { signalSelectedCustomerId } from '../../../../../state/admin-customers';
import { getAsSelectObject } from '../../../../../utils/admin/common.utils';

const CustomerList = ({ editedObject, customers }) => {
  const navigate = useNavigate();
  const [userCustomers, setUserCustomers] = useState([]);
  const availableCustomers = customers.filter(
    (customer) => !editedObject.customers.includes(customer.id)
  );

  useEffect(() => {
    setUserCustomers(
      customers.filter((customer) =>
        editedObject.customers.includes(customer.id)
      )
    );
  }, [customers, editedObject]);

  const handleUserCustomersChange = (newValue) => {
    signalEditedUser.value.customersAdd = newValue.map((obj) => obj.value);
    signalEditedUser.value.isDirty = true;
  };

  const handleDeleteCustomer = (customer) => {
    if (!signalEditedUser.value.customersRemove)
      signalEditedUser.value.customersRemove = [];

    signalEditedUser.value.customersRemove.push(customer.id);
    setUserCustomers(userCustomers.filter((c) => c.id !== customer.id));
    signalEditedUser.value.isDirty = true;
  };

  const handleSelectCustomer = (customer) => {
    signalSelectedCustomerId.value = customer.id;
    navigate(`/admin/customers/${customer.id}`);
  };

  return (
    <Row>
      <Col className="col-lg-6 col-sm-12">
        <div className="mb-3">
          <div className="dl-header">Tilldela</div>
          <Select
            isMulti
            isClearable
            closeMenuOnSelect={false}
            options={getAsSelectObject(availableCustomers, 'name', 'id')}
            onChange={handleUserCustomersChange}
            className="react-select w-100"
            placeholder="Kunder"
          />
        </div>

        {/* User Customers */}
        {userCustomers.map((customer, index) => (
          <ListItem name={`${customer.name}`} key={index} isSelectable={false}>
            <Button
              variant="outline-danger"
              onClick={() => handleDeleteCustomer(customer)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>

            <Button
              variant="outline-secondary"
              onClick={() => handleSelectCustomer(customer)}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </ListItem>
        ))}
      </Col>
    </Row>
  );
};

export default CustomerList;
