export const responsesCorrectAnswer = [
  'Rätt svar!',
  'Bra jobbat, det stämmer!',
  'Rätt svar, fantastiskt!',
  'Korrekt, du är på rätt spår!',
  'Exakt, du har helt rätt!',
  'Strålande, rätt svar!',
  'Perfekt, det är rätt!',
  'Bravo, det är korrekt!',
  'Utmärkt, du svarade rätt!',
  'Spot on, rätt svar!',
  'Suveränt, du har rätt!',
];

export const responsesWrongAnswer = [
  'Det är tyvärr fel, försök igen!',
  'Tyvärr, det var inte rätt, men bra försök!',
  'Inte riktigt, men du är nära!',
  'Bra försök, men det var inte rätt den här gången.',
  'Det var nästan rätt, försök igen!',
  'Inte rätt svar, men fortsätt försök!',
  'Tyvärr fel, men du lär dig!',
  'Inte rätt, men du är på god väg!',
  'Nästan där, men det var inte korrekt.',
  'Bra försök, men det stämmer inte helt.',
  'Tyvärr fel, men fortsätt kämpa!',
];
