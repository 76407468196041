import { faIdBadge } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';
import copy from 'copy-to-clipboard';
import { toastMessage } from '../../state/app-functions';

const DropdownCopyGuid = ({ guid }) => {
  const handleCopyGuid = () => {
    copy(guid);
    toastMessage(`GUID kopierad: ${guid}`);
  };

  return (
    <Dropdown.Item onClick={handleCopyGuid}>
      <FontAwesomeIcon icon={faIdBadge} className="pe-2" />
      Kopiera GUID
    </Dropdown.Item>
  );
};

export default DropdownCopyGuid;
