import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import PrettyJson from '../Shared/PrettyJson/PrettyJson';

const Test3 = () => {
  console.log('- Test3 -');
  const [data, setData] = useState(null);
  const [json, setJson] = useState(null);
  const [x, setX] = useState(null);

  useEffect(() => {
    console.log('useeffect [] runs on mount');
    const id = Math.floor(Math.random() * 100);

    fetch(`https://jsonplaceholder.typicode.com/posts/${id}`)
      .then((response) => response.json())
      .then((data) => {
        console.log('data:', data);
        setJson(data);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  // useEffect(() => {
  //   console.log('useeffect [data] runs on mount and data change');
  // }, [data]);

  // useEffect(() => {
  //   console.log('useeffect [x] runs on mount and x change');
  // }, [x]);

  const handleClick = (e) => {
    console.log('click');
    setData(e.clientX);
  };

  const handleClick2 = (e) => {
    console.log('click2');
    setData(1);
    setX(2);
  };

  return (
    <div>
      <h1>useEffect tests</h1>
      <div>
        <p>Data: {JSON.stringify(data)}</p>
        <p>X: {x}</p>
      </div>
      <div>
        <Button onClick={handleClick}>Click 1</Button>
      </div>
      <div>
        <Button onClick={handleClick2}>Click 2</Button>
      </div>
      <div>
        <PrettyJson data={json} collapsedLevel={1} />
      </div>
    </div>
  );
};

export default Test3;
