import React, { useState } from 'react';
import { Button, Card, Dropdown, Form } from 'react-bootstrap';
import EditableContent from '../../Shared/EditableContent/EditableContent';
import { userTemplate } from '../../../utils/admin/user.utils';
import { deleteUser, saveUser } from '../Users/utils';
import { signalEditedUser } from '../../../state/admin-users';
import './style.scss';
import DropdownCopyGuid from '../DropdownCopyGuid';
import User from './User/User';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  confirmModal,
  confirmModalOptionsDelete,
  toastMessage,
} from '../../../state/app-functions';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../PageHeader';
import { useQuery, useQueryClient } from 'react-query';
import { queryCustomers } from '../../../api/queries/queries.customer';

const UserModal = ({ editedObject }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const queryCustomer = useQuery(queryCustomers());
  const [userName, setUserName] = useState(editedObject.name);
  const [userActive, setUserActive] = useState(editedObject.isActive);

  const handleSaveUser = async () => {
    if (signalEditedUser.value.id === userTemplate.id) {
      if (signalEditedUser.value.email.length === 0) {
        toastMessage('E-postadress saknas', 'error');
        return;
      }

      if (signalEditedUser.value.password.length === 0) {
        toastMessage('Lösenord saknas', 'error');
        return;
      }
    }

    await saveUser(queryClient, navigate);
  };

  const handleDelete = () => {
    confirmModal(async () => {
      await deleteUser(editedObject.id, queryClient);
      navigate(`/admin/users`);
    }, confirmModalOptionsDelete);
  };

  const handleNameChange = (text) => {
    signalEditedUser.value.name = text;
    signalEditedUser.value.isDirty = true;
    setUserName(text);
  };

  const handleActiveChange = (e) => {
    const isActive = e.target.checked;
    signalEditedUser.value.isActive = isActive;
    signalEditedUser.value.isDirty = true;
    setUserActive(isActive);
  };

  return (
    <div className="component-admin-user">
      <PageHeader icon="users">
        {/* User Name */}
        <EditableContent
          className="me-4"
          value={userName}
          onChange={handleNameChange}
        />

        {/* Aktiv */}
        <Form.Check
          type="switch"
          id="user-aktiv-switch"
          className={userActive ? 'text-success' : 'text-muted'}
          label={userActive ? 'Aktiv' : 'Inaktiv'}
          checked={userActive}
          onChange={handleActiveChange}
        />

        {/* Actions */}
        {editedObject.id !== userTemplate.id && (
          <Dropdown className="ms-3">
            <Dropdown.Toggle variant="secondary"></Dropdown.Toggle>

            <Dropdown.Menu>
              <DropdownCopyGuid guid={editedObject.id} />
            </Dropdown.Menu>
          </Dropdown>
        )}

        {/* Save User Button */}
        <Button
          variant="primary"
          className="ms-4"
          onClick={() => handleSaveUser()}
        >
          Spara
        </Button>

        {/* Delete User Button */}
        {editedObject.id !== userTemplate.id && (
          <Button variant="danger" className="ms-auto" onClick={handleDelete}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        )}
      </PageHeader>

      {queryCustomer.isSuccess && (
        <Card className="mt-3">
          <Card.Body>
            <User editedObject={editedObject} customers={queryCustomer.data} />
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default UserModal;
