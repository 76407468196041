import React, { useState } from 'react';
import EditableContent from '../../Shared/EditableContent/EditableContent';
import Customer from './Customer/Customer';
import { Button, Card, Dropdown, Form } from 'react-bootstrap';
import { customerTemplate } from '../../../utils/admin/customer.utils';
import { deleteCustomer, saveCustomer } from '../Customers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {
  confirmModal,
  confirmModalOptionsDelete,
} from '../../../state/app-functions';
import './style.scss';
import DropdownCopyGuid from '../DropdownCopyGuid';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../PageHeader';
import { signalEditedCustomer } from '../../../state/admin-customers';
import { useQueryClient } from 'react-query';

const CustomerModal = ({ editedObject, users, courses }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [customerName, setCustomerName] = useState(editedObject.name);
  const [customerActive, setCustomerActive] = useState(editedObject.isActive);

  const handleNameChange = (value) => {
    signalEditedCustomer.value.name = value;
    signalEditedCustomer.value.isDirty = true;
    setCustomerName(value);
  };

  const handleActiveChange = (e) => {
    const isActive = e.target.checked;
    signalEditedCustomer.value.isActive = isActive;
    signalEditedCustomer.value.isDirty = true;
    setCustomerActive(isActive);
  };

  // Delete
  const handleDelete = () => {
    confirmModal(async () => {
      await deleteCustomer(queryClient);
      navigate(`/admin/customers`);
    }, confirmModalOptionsDelete);
  };

  return (
    <div className="component-admin-customer">
      <PageHeader icon="customers">
        {/* Customer Name */}
        <EditableContent
          className="me-4"
          value={customerName}
          onChange={handleNameChange}
        />

        {/* Aktiv */}
        <Form.Check
          type="switch"
          id="customer-aktiv-switch"
          className={customerActive ? 'text-success' : 'text-muted'}
          label={customerActive ? 'Aktiv' : 'Inaktiv'}
          checked={customerActive}
          onChange={handleActiveChange}
        />

        {/* Actions */}
        {editedObject.id !== customerTemplate.id && (
          <Dropdown className="ms-3">
            <Dropdown.Toggle variant="secondary"></Dropdown.Toggle>

            <Dropdown.Menu>
              <DropdownCopyGuid guid={editedObject.id} />
            </Dropdown.Menu>
          </Dropdown>
        )}

        {/* Save Customer Button */}
        <Button
          variant="primary"
          className="ms-4"
          onClick={async () => {
            await saveCustomer(queryClient, navigate);
          }}
        >
          Spara
        </Button>

        {/* Delete Customer Button */}
        {editedObject.id !== customerTemplate.id && (
          <Button variant="danger" className="ms-auto" onClick={handleDelete}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        )}
      </PageHeader>

      <Card className="mt-3">
        <Card.Body>
          <Customer
            editedObject={editedObject}
            users={users}
            courses={courses}
          />
        </Card.Body>
      </Card>
    </div>
  );
};

export default CustomerModal;
