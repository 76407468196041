import React from 'react';
import { Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDarkMode } from '../../../hooks/useDarkMode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faBug,
  faBuilding,
  faChartLine,
  faChevronLeft,
  faChevronRight,
  faFileAlt,
  faMoon,
  faQuestion,
  faSearch,
  faSignOut,
  faSun,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import './style.scss';
import logo from '../../../assets/images/logo.png';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { getJwtToken, setJwtToken } from '../../../api/api.client';
import { jwtDecode } from 'jwt-decode';
import { useQueries } from 'react-query';
import { queryUsers } from '../../../api/queries/queries.user';
import Glow from '../../Shared/Glow/Glow';

/*
https://github.com/azouaoui-med/react-pro-sidebar/blob/master/storybook/Playground.tsx
https://codesandbox.io/p/sandbox/react-d6h40z?file=%2Fsrc%2FSidebar.js
*/

const AdminSidebar = () => {
  const navigate = useNavigate();

  const results = useQueries([queryUsers()]);
  const isSuccess = results.every((result) => result.isSuccess);
  const [usersQuery] = results;

  const [darkMode, setDarkMode] = useDarkMode();
  const [sidebarCollapsed, setSidebarCollapsed] = useLocalStorage(
    'sidebarCollapsed',
    false
  );

  const decodedJwt = jwtDecode(getJwtToken());
  // console.log(decodedJwt);

  const handleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleCollapsed = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  const handleProfile = () => {
    const user = usersQuery.data.find((u) => u.name === decodedJwt.sub);
    navigate(`/admin/users/${user.id}`);
  };

  const handleLogout = () => {
    setJwtToken(null);

    // We cannot use navigate() here as we need the routes to reload
    window.location.href = '/login';
  };

  if (!isSuccess) return <></>;

  return (
    <div className="component-admin-sidebar">
      <Sidebar collapsed={sidebarCollapsed} toggled={false}>
        <Menu>
          {sidebarCollapsed ? (
            <MenuItem
              icon={<FontAwesomeIcon icon={faChevronRight} />}
              onClick={handleCollapsed}
            ></MenuItem>
          ) : (
            <MenuItem
              suffix={<FontAwesomeIcon icon={faChevronLeft} />}
              onClick={handleCollapsed}
            >
              <div className="header-title">
                <div className="d-flex align-items-center">
                  <img src={logo} alt="logo" width="25" className="me-1" />
                  <span>DL Admin</span>
                  <span className="version">
                    &nbsp;(v{process.env.REACT_APP_VERSION})
                  </span>
                </div>
              </div>
            </MenuItem>
          )}
          <hr />
        </Menu>
        <Menu>
          <MenuItem
            icon={<FontAwesomeIcon icon={faSearch} />}
            component={<NavLink to="/admin/search" />}
          >
            Sök
          </MenuItem>

          <MenuItem
            icon={<FontAwesomeIcon icon={faBuilding} />}
            component={<NavLink to="/admin/customers" />}
          >
            Kunder
          </MenuItem>

          <MenuItem
            icon={<FontAwesomeIcon icon={faBook} />}
            component={<NavLink to="/admin/courses" />}
          >
            Kurser
          </MenuItem>

          <MenuItem
            icon={<FontAwesomeIcon icon={faFileAlt} />}
            component={<NavLink to="/admin/lessons" />}
          >
            Lektioner
          </MenuItem>

          <MenuItem
            icon={<FontAwesomeIcon icon={faUser} />}
            component={<NavLink to="/admin/users" />}
          >
            Användare
          </MenuItem>

          <MenuItem
            icon={<FontAwesomeIcon icon={faChartLine} />}
            component={<NavLink to="/admin/statistics" />}
          >
            Statistik
          </MenuItem>

          <Menu>
            <hr />

            {/* Logged in user */}
            <SubMenu
              label={decodedJwt.sub}
              icon={
                <Glow type="legendary" borderRadius="50%">
                  <div className="avatar">
                    <div>{decodedJwt.sub.charAt(0)}</div>
                  </div>
                </Glow>
              }
            >
              {/* Logout */}
              <MenuItem
                icon={<FontAwesomeIcon icon={faSignOut} size="lg" />}
                onClick={() => handleLogout()}
              >
                Logga ut
              </MenuItem>

              {/* Profile */}
              <MenuItem
                icon={<FontAwesomeIcon icon={faUser} />}
                onClick={() => handleProfile()}
              >
                Profil
              </MenuItem>

              {/* Tanstack Queries */}
              <MenuItem
                icon={<FontAwesomeIcon icon={faBug} />}
                component={<NavLink to="/admin/queries" />}
              >
                Queries
              </MenuItem>
              {decodedJwt.sub === 'Carl-Johan Schnell' && (
                <SubMenu
                  label="Tests"
                  icon={<FontAwesomeIcon icon={faQuestion} />}
                >
                  <MenuItem
                    icon={<FontAwesomeIcon icon={faQuestion} />}
                    component={
                      <NavLink to="/admin/test1/74d3cb8b-93ce-47b1-bc39-f0348bb4bc69" />
                    }
                  >
                    Test 1
                  </MenuItem>

                  <MenuItem
                    icon={<FontAwesomeIcon icon={faQuestion} />}
                    component={
                      <NavLink to="/admin/test2/a93b69b5-5677-4704-b51c-85fc72718db2" />
                    }
                  >
                    Test 2
                  </MenuItem>

                  <MenuItem
                    icon={<FontAwesomeIcon icon={faQuestion} />}
                    component={
                      <NavLink to="/admin/test3/a93b69b5-5677-4704-b51c-85fc72718db2" />
                    }
                  >
                    Test 3
                  </MenuItem>
                </SubMenu>
              )}
            </SubMenu>

            {/* Dark Mode */}
            <MenuItem
              icon={
                <FontAwesomeIcon icon={darkMode ? faMoon : faSun} size="lg" />
              }
              onClick={() => handleDarkMode()}
            >
              Theme
            </MenuItem>
          </Menu>
        </Menu>
      </Sidebar>
    </div>
  );
};

export default AdminSidebar;
