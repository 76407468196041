import React, { useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { saveAnswer, showNextSlide } from '../../../utils/home/lesson';
import { toastMessage } from '../../../state/app-functions';
import Answer from '../Answer/Answer';
import RippleButton from '../../Shared/RippleButton/RippleButton';
import ButtonAnswerText from './ButtonAnswerText';
import { signalLessonData, signalUserId } from '../../../state/app.signals';

import './style.scss';
import { handleApiError } from '../../../api/api.client';

const NumberInput = ({ slide }) => {
  const [hasAnswered, setHasAnswered] = useState(slide.hasAnswered);

  const { register, getValues } = useForm({
    defaultValues: { antal: slide.answers === null ? '' : slide.answers[0] },
  });

  const handleKeyPress = (e) => {
    if (e.key !== 'Enter') return;

    handleOnClick();
  };

  const handleOnChange = (e) => {
    let svar = e.target.value.trim();

    if (svar.length > 0) {
      svar = Number(svar.replace(/\D/g, '')); // Only digits
    }

    e.target.value = svar; // Set value in the input field
  };

  const handleOnClick = async () => {
    let svar = getValues('antal').trim();

    if (svar.length === 0) {
      toastMessage('Vänligen svara på frågan');
      return;
    }

    slide.answers = [`${svar}`];

    if (signalLessonData.value.isPreview) {
      toastMessage(
        'Ditt svar är inte sparat eftersom detta är en förhandsgranskning'
      );
      setHasAnswered(true);
      showNextSlide(slide, true);
      return;
    }

    try {
      await saveAnswer(signalUserId.value, slide);
    } catch (error) {
      handleApiError(error, 'Ett fel uppstod när ditt svar skulle sparas');
      return;
    }

    setHasAnswered(true);
    showNextSlide(slide, true);
  };

  return (
    <div className="component-fraga-number mb-1">
      <div className="d-flex">
        <FormControl
          className="input-antal"
          type="text"
          size="lg"
          {...register('antal', {
            onChange: handleOnChange,
          })}
          onKeyPress={(e) => handleKeyPress(e)}
          disabled={hasAnswered}
        />
        <RippleButton
          className="btn btn-lg btn-secondary ms-2"
          onClick={handleOnClick}
          disabled={hasAnswered}
        >
          <ButtonAnswerText hasAnswered={hasAnswered}>Svara</ButtonAnswerText>
        </RippleButton>
      </div>

      <Answer slide={slide} hasAnswered={hasAnswered} />
    </div>
  );
};

export default NumberInput;
