import React, { useEffect, useState } from 'react';
import { Accordion, Button, Modal } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import PrettyJson from '../../Shared/PrettyJson/PrettyJson';
import CopyToClipboardButton from '../../Shared/CopyToClipboardButton/CopyToClipboardButton';
import './style.scss';
import useExactQueryClient from '../../../api/queries/useExactQueryClient';

const Queries = () => {
  const queryClient = useQueryClient();
  const { invalidateExactQueries, removeExactQueries, refetchExactQueries } =
    useExactQueryClient();

  const [queries, setQueries] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [queryData, setQueryData] = useState({});

  useEffect(() => {
    const existingQueries = queryClient.getQueryCache().findAll();
    setQueries(existingQueries);
  }, [queryClient]);

  const removeQuery = (queryKey) => {
    removeExactQueries(queryKey);
    // Update the state to reflect the removed query
    setQueries(queries.filter((query) => query.queryKey !== queryKey));
  };

  const refetchQuery = async (queryKey) => {
    await refetchExactQueries(queryKey);
    const data = queryClient.getQueryData(queryKey);
    console.log('Refetched data:', data);
  };

  const markQueryAsStale = (queryKey) => {
    // Marks the query as stale and triggers a refetch
    invalidateExactQueries(queryKey);
  };

  const handleRefresh = () => {
    const existingQueries = queryClient.getQueryCache().findAll();
    setQueries(existingQueries);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleShowModal = (query) => {
    setQueryData(query);
    setShowModal(true);
  };

  return (
    <div className="component-queries">
      <div className="d-flex align-items-center mb-2">
        <h1>React Queries</h1>
        <Button onClick={handleRefresh} variant="primary" className="ms-3">
          Refresh
        </Button>
      </div>

      {/* Queries */}
      <ul>
        {queries
          .sort((a, b) => {
            const keyA = JSON.stringify(a.queryKey);
            const keyB = JSON.stringify(b.queryKey);
            return keyA.localeCompare(keyB);
          })
          .map((query, index) => {
            return (
              <Accordion key={index} defaultActiveKey="0">
                <Accordion.Item>
                  <Accordion.Header>
                    {JSON.stringify(query.queryKey)}
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="d-flex align-items-center mb-2">
                      <b className="me-2">Query Key:</b>

                      <code>{JSON.stringify(query.queryKey)}</code>

                      <CopyToClipboardButton
                        className="ms-2"
                        text={JSON.stringify(query.queryKey)}
                      />

                      <div className="ms-auto">
                        <Button
                          variant="danger"
                          className="ms-3"
                          onClick={() => removeQuery(query.queryKey)}
                        >
                          Remove
                        </Button>

                        <Button
                          variant="info"
                          className="ms-3"
                          onClick={() => markQueryAsStale(query.queryKey)}
                        >
                          Stale
                        </Button>

                        <Button
                          variant="success"
                          className="ms-3"
                          onClick={() => refetchQuery(query.queryKey)}
                        >
                          Refretch
                        </Button>
                        <Button
                          variant="secondary"
                          className="ms-3"
                          onClick={() => handleShowModal(query)}
                        >
                          Data
                        </Button>
                      </div>
                    </div>

                    <div>
                      <b>Options:</b> <PrettyJson data={query.options} />
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            );
          })}
      </ul>

      <Button
        onClick={() => queryClient.removeQueries(['users'], { exact: true })}
        variant="secondary"
        className="mt-3"
      >
        Test
      </Button>

      <Modal size="lg" show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Query State</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PrettyJson data={queryData.state} collapsedLevel={1} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Queries;
