import { handleApiError } from '../api.client';

export const queryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 10, // 10 minutes
      refetchOnWindowFocus: false,
      onError: (error) => {
        handleApiError(error, 'Ett fel inträffade');
      },
    },
  },
};
