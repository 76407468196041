import { apiClient } from '../api.client';
import { userTemplate } from '../../utils/admin/user.utils';
import { sortByProp } from '../../utils/utils';

export const queryUsers = () => {
  return {
    queryKey: ['users'],
    queryFn: async () => {
      const result = await apiClient.get(`users`);
      return result.data.sort(sortByProp('name'));
    },
  };
};

export const queryUserDetail = (id) => ({
  queryKey: ['users', 'detail', id],
  queryFn: async () => {
    if (id === userTemplate.id) return { ...userTemplate };

    const result = await apiClient.get(`users/${id}`);
    return result.data;
  },
});
