import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorBoundaryFallback from '../../../components/Shared/ErrorBoundaryFallback/ErrorBoundaryFallback';
import { changeFavicon } from '../../../utils/image.util';
import { adminFavicon } from '../../../utils/base64.images';
import AdminSidebar from '../../../components/Admin/AdminSidebar/AdminSidebar';
import './admin.style.scss';
import { removeCustomerStyles } from '../../../utils/admin/common.utils';

const LayoutAdmin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === 'f') {
        event.preventDefault();

        // console.log('Ctrl+F pressed');
        navigate(`/admin/search`);

        setTimeout(() => {
          document.getElementById('search')?.focus();
        }, 100);

        return;
      }

      if (event.altKey && event.key === 'p') {
        event.preventDefault();
        // console.log('Alt+P pressed');
        return;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      // Clean up the event listener on component unmount
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [navigate]);

  changeFavicon(adminFavicon);
  removeCustomerStyles();

  return (
    <div className="vh-100 d-flex">
      <ErrorBoundary
        FallbackComponent={ErrorBoundaryFallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
      >
        <AdminSidebar />
      </ErrorBoundary>

      <main className="admin-sidebar-main w-100">
        <Container>
          <ErrorBoundary
            FallbackComponent={ErrorBoundaryFallback}
            onReset={() => {
              // reset the state of your app so the error doesn't happen again
            }}
          >
            <Outlet />
          </ErrorBoundary>
        </Container>
      </main>
    </div>
  );
};

export default LayoutAdmin;
