import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Row, Col, Container } from 'react-bootstrap';
import ErrorBoundaryFallback from '../../components/Shared/ErrorBoundaryFallback/ErrorBoundaryFallback';
import Header from '../../components/Shared/Header/Header';
import { Outlet } from 'react-router-dom';

const LayoutLesson = () => {
  return (
    <Container>
      <Row className="vh-100">
        <Col>
          {/* Header */}
          <Row className="justify-content-center">
            <Col>
              <ErrorBoundary
                FallbackComponent={ErrorBoundaryFallback}
                onReset={() => {
                  // reset the state of your app so the error doesn't happen again
                }}
              >
                <Header />
              </ErrorBoundary>
            </Col>
          </Row>

          {/* Page Content */}
          <Row className="justify-content-center">
            <Col className="mb-2">
              <ErrorBoundary
                FallbackComponent={ErrorBoundaryFallback}
                onReset={() => {
                  // reset the state of your app so the error doesn't happen again
                }}
              >
                <Outlet />
              </ErrorBoundary>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default LayoutLesson;
