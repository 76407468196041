import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import UserModal from './UserModal';
import {
  signalEditedUser,
  signalSelectedUserId,
} from '../../../state/admin-users';
import { queryUserDetail } from '../../../api/queries/queries.user';

const UserLoader = () => {
  const params = useParams();
  const query = useQuery(queryUserDetail(params.guid));

  // console.log('UserLoader', params.guid, query.data);

  if (query.isError) {
    console.error('Användare kunde inte hämtas', query.error);
  }

  if (query.isSuccess) {
    signalSelectedUserId.value = query.data.id;
    signalEditedUser.value = query.data;
    return <UserModal key={query.data.id} editedObject={query.data} />;
  }

  return <></>;
};

export default UserLoader;
