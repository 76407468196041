import React from 'react';
import { ATTACHMENT_TYPE } from '../../../../utils/constants';
import ImageUpload from '../../../Shared/ImageUpload/ImageUpload';
import { getBase64FromDataUrl } from '../../../../utils/files.utils';
import { signalEditedQuestion } from '../../../../state/admin-courses';
import './style.scss';

const QuestionMedia = () => {
  const editedObject = signalEditedQuestion.value;

  const handleImageChange = (imageList) => {
    editedObject.attachmentId = null; // New/Removed image, so null the id

    const image = imageList[0];

    editedObject.isDirty = true;

    // Add image
    if (image) {
      editedObject.attachmentContent = getBase64FromDataUrl(image.data_url);
      editedObject.attachmentName = image.file.name;
      editedObject.attachmentType = ATTACHMENT_TYPE.IMAGE;
      return;
    }

    // Remove image
    editedObject.attachmentContent = null;
    editedObject.attachmentName = null;
    editedObject.attachmentType = ATTACHMENT_TYPE.IMAGE;
  };

  return (
    <div id="component-question-media-container">
      <ImageUpload
        imageBase64={editedObject.attachmentContent}
        imageName={editedObject.attachmentName}
        uploadResizeMaxPixels={500}
        confirmOnRemove={true}
        onChange={handleImageChange}
      />
    </div>
  );
};

export default QuestionMedia;
