import { useRef, useState } from 'react';
import Select from 'react-select';
import './style.scss';
import { getAsSelectObject } from '../../../../../utils/admin/common.utils';
import { Accordion, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { saveCustomerCoursesUsers } from '../../../Customers/utils';
import { previewInstancedLesson } from '../../../Courses/utils';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import ListItem from '../../../ListItem/ListItem';
import { signalSelectedCustomerId } from '../../../../../state/admin-customers';
import { toastMessage } from '../../../../../state/app-functions';
import { apiClient, handleApiError } from '../../../../../api/api.client';
import { useQueryClient } from 'react-query';

const CourseList = ({ editedObject, users, courses, iCourses }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [course, setCourse] = useState(null);
  const [instansiatedCourses, setInstansiatedCourses] = useState(iCourses);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [courseUsers, setCourseUsers] = useState([]);
  const selectectableCourses = getAsSelectObject(courses, 'name', 'id');
  const customerUsers = users.filter((user) =>
    user.customers.includes(editedObject.id)
  );

  const selectCourseRef = useRef(null);

  const handleCoursesChange = (course) => {
    setCourse(course);
  };

  const handleInstanciateCourse = async () => {
    // console.log('instanciate course', course);

    try {
      const instantiatedCourse = await apiClient.post(
        `customer/${signalSelectedCustomerId.value}/courses/${course.value}`
      );
      queryClient.removeQueries(['customers', 'detail', editedObject.id]);
      console.log('instantiatedCourse', instantiatedCourse.data);

      setInstansiatedCourses([...instansiatedCourses, instantiatedCourse.data]);
      if (!editedObject.availibleCourses) editedObject.availibleCourses = [];
      editedObject.availibleCourses.push(instantiatedCourse.data.id);

      setCourse(null);
      selectCourseRef.current?.clearValue();
    } catch (error) {
      handleApiError(error, 'Kursen kunde inte instansieras');
    }
  };

  const handlePreviewLesson = async (e, course, lesson) => {
    e.stopPropagation();
    await previewInstancedLesson(
      navigate,
      editedObject.id,
      lesson.id,
      course.id
    );
  };

  const handleAssignUsersToCourse = (course) => {
    console.log('course', course);
    setCourseUsers([]);
    setSelectedCourse(course);
  };

  const handleCourseUsersChange = (e, user) => {
    if (e.target.checked === true) {
      setCourseUsers([...courseUsers, user.id]);
      return;
    }

    setCourseUsers(courseUsers.filter((userId) => userId !== user.id));
  };

  const handleSaveCourseUsers = async () => {
    if (courseUsers.length === 0) {
      toastMessage('Inga användare valda', 'error');
      return;
    }

    await saveCustomerCoursesUsers(selectedCourse.id, courseUsers, queryClient);
    setSelectedCourse(null);
  };

  return (
    <Row>
      {/* Customer courses */}
      <Col className="mt-2">
        {editedObject.availibleCourses && (
          <Button
            variant="primary"
            onClick={() => {
              navigate(`/admin/statistics/${editedObject.id}`);
            }}
            className="mb-3"
          >
            Statistik
          </Button>
        )}

        <div className="d-flex align-items-center mb-3">
          <Select
            ref={selectCourseRef}
            isClearable
            isSearchable={true}
            isMulti={false}
            options={selectectableCourses}
            onChange={handleCoursesChange}
            placeholder="Välj kurs"
            tabSelectsValue={false}
            className="react-select w-100"
          />

          <Button
            variant="success"
            disabled={!course}
            className="ms-3"
            onClick={handleInstanciateCourse}
          >
            Instansiera
          </Button>
        </div>

        {[...instansiatedCourses].reverse().map((course, index) => (
          <Accordion key={course.id}>
            <Accordion.Item eventKey={index}>
              <Accordion.Header>
                <span>{course.name}</span>
                <span className="text-muted ps-2">v{course.courseVersion}</span>
              </Accordion.Header>
              <Accordion.Body>
                <Button
                  className="mb-2"
                  onClick={() => handleAssignUsersToCourse(course)}
                >
                  Tilldela användare
                </Button>
                {course.lessons.map((lesson, index) => (
                  <div key={index}>
                    <ListItem
                      name={`${lesson.name} (v${lesson.version})`}
                      key={index}
                      isSelectable={false}
                    >
                      <Button
                        variant="outline-secondary"
                        onClick={(e) => handlePreviewLesson(e, course, lesson)}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </Button>
                    </ListItem>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ))}
      </Col>

      {/* Assign users to course */}
      <Col>
        {selectedCourse && (
          <Card>
            <Card.Body>
              <Card.Title>
                <div className="d-flex align-items-center">
                  <h4>
                    <span className="text-muted">Användare för </span>
                    {selectedCourse.name}
                  </h4>
                  <Button
                    variant="link"
                    className="ms-auto me-2"
                    onClick={() => setSelectedCourse(null)}
                  >
                    Avbryt
                  </Button>
                  <Button
                    variant="success"
                    disabled={courseUsers.length === 0}
                    onClick={handleSaveCourseUsers}
                  >
                    Spara
                  </Button>
                </div>
              </Card.Title>
              {customerUsers.map((user, index) => (
                <Form.Check
                  key={index}
                  id={user.id}
                  label={user.name}
                  type="checkbox"
                  disabled={
                    user.courses?.filter((x) => x.id === selectedCourse.id)
                      .length > 0
                  }
                  onChange={(e) => {
                    handleCourseUsersChange(e, user);
                  }}
                />
              ))}
            </Card.Body>
          </Card>
        )}
      </Col>
    </Row>
  );
};

export default CourseList;
