// Used to create select options for "react-select"
export function getAsSelectObject(arr, propLabel, propValue = null) {
  // console.log('getAsSelectObject:', arr);
  if (!arr) return [];

  return arr.map((obj, index) => {
    return {
      label: obj[propLabel],
      value: propValue ? obj[propValue] : index,
    };
  });
}

export const removeCustomerStyles = () => {
  sessionStorage.removeItem('css');

  const stylesToRemove = document.querySelectorAll(
    'head style[data-customerstyle="1"]'
  );
  stylesToRemove.forEach((style) => style.remove());
};
