import { useRef } from 'react';
import { QueryClient } from 'react-query';
import { useLoaderData, useParams } from 'react-router-dom';

const Test2 = () => {
  console.log('- Test2 -');
  const queryClient = new QueryClient();
  const params = useParams();

  //   const ref = useRef(useLoaderData());
  //   console.log('Test2 data', ref.current);

  //   const data = useLoaderData();
  //   console.log('Test2 data', data);

  return (
    <div>
      <h1>Test 2</h1>
    </div>
  );
};

export default Test2;
