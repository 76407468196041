import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { customerTemplate } from '../../../utils/admin/customer.utils';
import AddButton from '../../Shared/AddButton/AddButton';
import { setSelectedCustomer } from './utils';
import { signalSelectedCustomerId } from '../../../state/admin-customers';
import './style.scss';
import ListItem from '../ListItem/ListItem';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../PageHeader';
import { useQuery } from 'react-query';
import { queryCustomers } from '../../../api/queries/queries.customer';

const Customers = () => {
  const { data, isLoading } = useQuery(queryCustomers());
  const navigate = useNavigate();

  const handleEditCustomer = async (e, customerId) => {
    e.stopPropagation();
    setSelectedCustomer(customerId);
    navigate(`/admin/customers/${customerId}`);
  };

  const handleNewCustomer = () => {
    setSelectedCustomer(null);
    navigate(`/admin/customers/${customerTemplate.id}`);
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <PageHeader title="Kunder" icon="customers" />

      <Row>
        <Col className="col-lg-6 col-sm-12">
          {/* Customers */}
          {data.map(function (customer, index) {
            return (
              <ListItem
                name={customer.name}
                key={index}
                active={signalSelectedCustomerId.value === customer.id}
                onClick={async (e) => {
                  console.log('Selected Customer', customer.id);
                  setSelectedCustomer(customer.id);
                  await handleEditCustomer(e, customer.id);
                }}
              />
            );
          })}

          <div className="mt-2">
            {/* Add Customer */}
            <AddButton onClick={handleNewCustomer} />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Customers;
