import { useQuery } from 'react-query';
import { queryCustomerDetailCourseDetail } from '../../../api/queries/queries.customer';
import { Col } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import {
  signalCourseName,
  signalCourseVersion,
  signalCustomerName,
} from './CustomerCoursesLayout';
import ListItem from '../ListItem/ListItem';

const CustomerCourses = () => {
  const navigate = useNavigate();
  const params = useParams();

  signalCourseName.value = null;
  signalCourseVersion.value = null;

  const { data: customer, isSuccess } = useQuery(
    queryCustomerDetailCourseDetail(params.customerId)
  );

  const handleCourseClick = (course) => {
    navigate(`/admin/statistics/${params.customerId}/courses/${course.id}`);
  };

  if (!isSuccess) return <></>;

  signalCustomerName.value = customer.name;

  console.log('customer', customer);

  return (
    <Col lg="6">
      {[...customer.instancedCourseDetails].reverse().map((course, index) => (
        <ListItem
          name={
            <div>
              <span>{course.name}</span>
              <span className="text-muted ps-2">v{course.courseVersion}</span>
            </div>
          }
          key={index}
          onClick={() => handleCourseClick(course)}
        />
      ))}
    </Col>
  );
};

export default CustomerCourses;
