import { useParams } from 'react-router-dom';
import { useQueries } from 'react-query';
import {
  signalEditedCustomer,
  signalSelectedCustomerId,
} from '../../../state/admin-customers';
import CustomerModal from './CustomerModal';
import { getCustomerById } from '../Customers/utils';
import {
  queryCustomerDetail,
  queryCustomers,
} from '../../../api/queries/queries.customer';
import { customerTemplate } from '../../../utils/admin/customer.utils';
import { queryUsers } from '../../../api/queries/queries.user';
import { queryCourses } from '../../../api/queries/queries.course';

const CustomerLoader = () => {
  console.log('- CustomerLoader -');
  signalEditedCustomer.value = null;

  const params = useParams();

  const results = useQueries([
    queryCustomerDetail(params.guid),
    queryCustomers(),
    queryCourses(),
    queryUsers(),
  ]);

  const isSuccess = results.every((result) => result.isSuccess);

  const [customerDetailQuery, customersQuery, coursesQuery, usersQuery] =
    results;

  if (isSuccess) {
    let queryCustomer = customerDetailQuery.data;

    if (queryCustomer.id !== customerTemplate.id) {
      // Existing customer
      queryCustomer = getCustomerById(customersQuery.data, queryCustomer.id);
    }

    signalSelectedCustomerId.value = queryCustomer.id;
    signalEditedCustomer.value = { ...queryCustomer };

    return (
      <CustomerModal
        editedObject={queryCustomer}
        users={usersQuery.data}
        courses={coursesQuery.data}
      />
    );
  }

  return <></>;
};

export default CustomerLoader;
