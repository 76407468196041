import { Navigate } from 'react-router-dom';
import LayoutAdmin from '../containers/Layout/LayoutAdmin/LayoutAdmin';
import Search from '../components/Admin/Search/Search';
import Courses from '../components/Admin/Courses/Courses';
import Customers from '../components/Admin/Customers/Customers';
import PageNotFound from '../pages/PageNotFound/PageNotFound';
import Users from '../components/Admin/Users/Users';
import UserLoader from '../components/Admin/UserModal/UserLoader';
import CustomerLoader from '../components/Admin/CustomerModal/CustomerLoader';
import Queries from '../components/Admin/Queries/Queries';
import Test1 from '../components/Admin/Test1';
import Test2 from '../components/Admin/Test2';
import Test3 from '../components/Admin/Test3';
import CustomerCourses from '../components/Admin/Statistics/CustomerCourses';
import CustomerCourseStatistics from '../components/Admin/Statistics/CustomerCourseStatistics';
import CustomerCoursesLayout from '../components/Admin/Statistics/CustomerCoursesLayout';
import CustomerStatistics from '../components/Admin/Statistics/CustomerStatistics';

export default function privateRoutes() {
  return {
    path: '/admin',
    element: <LayoutAdmin />,
    children: [
      { path: '', element: <Navigate to="search" replace /> },
      {
        path: 'search',
        element: <Search />,
      },
      { path: 'courses', element: <Courses /> },
      // // { path: 'lessons', element: <Lessons /> },
      { path: 'customers', element: <Customers /> },
      {
        path: 'customers/:guid',
        element: <CustomerLoader />,
      },
      {
        path: 'users',
        element: <Users />,
      },
      {
        path: 'users/:guid',
        element: <UserLoader />,
      },
      {
        path: 'statistics',
        element: <CustomerCoursesLayout />,
        children: [
          {
            path: '',
            element: <CustomerStatistics />,
          },
          {
            path: ':customerId',
            element: <CustomerCourses />,
          },
          {
            path: ':customerId/courses/:courseId',
            element: <CustomerCourseStatistics />,
          },
        ],
      },
      {
        path: 'test1/:guid',
        element: <Test1 />,
      },
      {
        path: 'test2/:guid',
        element: <Test2 />,
      },
      {
        path: 'test3/:guid',
        element: <Test3 />,
      },
      {
        path: 'queries',
        element: <Queries />,
      },
      { path: '*', element: <PageNotFound /> },
    ],
  };
}
