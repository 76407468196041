import React, { useEffect, useState } from 'react';
import { useDarkMode } from '../../../hooks/useDarkMode';
import { signalLessonData } from '../../../state/app.signals';
import { useLocation } from 'react-router-dom';
import logoLightBriab from '../../../assets/briab/logo/briab-logo-white.svg';
import logoBlackBriab from '../../../assets/briab/logo/briab-logo-black.svg';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { goToOverview } from '../../../utils/home/lesson';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state;
  const [darkMode, setDarkMode] = useDarkMode();
  const [logoLight, setLogoLight] = useState(logoLightBriab);
  const [logoDark, setLogoDark] = useState(logoBlackBriab);

  useEffect(() => {
    const logoLightSession = sessionStorage.getItem('logoLight');
    const logoDarkSession = sessionStorage.getItem('logoDark');

    if (logoLightSession) {
      setLogoLight(`data:image/png;base64,${logoLightSession}`);
    }
    if (logoDarkSession) {
      setLogoDark(`data:image/png;base64,${logoDarkSession}`);
    }
  }, [location]);

  const handleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className="component-home-header">
      <div className="section-left">
        {/* Course overview */}
        {!signalLessonData.value?.isPreview &&
          !location.pathname.includes('/lesson/select') && (
            <FontAwesomeIcon
              className="clickable-icon me-4"
              icon={faBook}
              size="xl"
              onClick={() => goToOverview(navigate, locationState)}
            />
          )}

        {/* Dark Mode */}
        <FontAwesomeIcon
          className="clickable-icon"
          icon={darkMode ? faMoon : faSun}
          size="lg"
          onClick={() => handleDarkMode()}
        />
      </div>

      <div className="section-center">
        {/* Lesson Name */}
        <span className="text-muted">{signalLessonData.value?.name}</span>
      </div>

      <div className="section-right">
        {/* Logo */}
        <img
          src={darkMode ? logoDark : logoLight}
          className="logo"
          alt="Logotyp"
        />
      </div>
    </div>
  );
};

export default Header;
