import { apiClient } from '../api.client';

export const queryLessons = () => {
  return {
    queryKey: ['lessons'],
    queryFn: async () => {
      const result = await apiClient.get(`lessons`);
      return result.data;
    },
  };
};
