import React from 'react';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';
import './style.scss';

export default function PrettyJson({ data, collapsedLevel = 0 }) {
  if (!data) {
    return null;
  }

  return (
    <JsonView
      theme="default" // default | a11y | github | vscode | atom| winter-is-coming
      editable={false}
      enableClipboard={true}
      collapsed={collapsedLevel}
      src={data}
    />
  );
}
