import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider } from './hooks/useAuth';
import { signalLoadingBar, signalLoadingBarRef } from './state/app.signals';
import { Toaster } from 'react-hot-toast';
import privateRoutes from './routes/private.routes';
import publicRoutes from './routes/public.routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import './global.style.scss';
import LoadingBar from 'react-top-loading-bar';
import ConfirmModal from './components/Shared/ConfirmModal/ConfirmModal';
import { getJwtToken } from './api/api.client';
import { queryClientConfig } from './api/queries/queryclient.config';

const checkAuth = () => {
  const token = getJwtToken();
  return token !== null && token.length > 0;
};

// Routes for the application
const router = createBrowserRouter([
  checkAuth() ? privateRoutes() : {},
  ...publicRoutes(),
]);

const App = () => {
  const queryClient = new QueryClient(queryClientConfig);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>

      <ConfirmModal />
      <Toaster position="bottom-center" />
      <LoadingBar
        color={'red'}
        progress={signalLoadingBar.value}
        onLoaderFinished={() => (signalLoadingBar.value = 0)}
      />
      <LoadingBar
        color={'red'}
        ref={(ref) => (signalLoadingBarRef.value = ref)}
      />
    </QueryClientProvider>
  );
};

export default App;
