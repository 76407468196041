import { Link, useLocation, useNavigate } from 'react-router-dom';
import { goToOverview } from '../../../utils/home/lesson';
import logo from '../../../assets/images/logo.png';
import './style.scss';

const End = (slide) => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state;

  return (
    <div className="component-slide-end">
      <div>
        <header>
          <h1>
            <img src={logo} alt="logo" width="50" className="me-3" />
            Lektionen är klar!
          </h1>
        </header>

        <div className="main-content">
          <p className="text-muted">
            Vi hoppas att du fann innehållet värdefullt och att du lärde dig
            något nytt. <br />
            Tack än en gång och ha en fortsatt bra dag!
          </p>

          <p className="text-muted">
            Du kan nu stäng sidan för att avsluta eller återgå till&nbsp;
            <Link onClick={() => goToOverview(navigate, locationState)}>
              kursöversikten
            </Link>
            .
          </p>
        </div>

        <footer>
          <p></p>
        </footer>
      </div>
    </div>
  );
};

export default End;
